<style lang="less" scoped>
@import "../../../styles/variable.less";
@antt: .ant-table;
.contact{
  &-table{
    /deep/ @{antt} {
      &-fixed {
        border-top: solid 1px @clear-black02;
      }
      @{antt}-content{
        @{antt}{
          &-thead {
            white-space: nowrap;
            & > tr {
              & > th {
                font-size: 10px;
                color: @clear-black03;
                background: @white01;
                border: none;
              }
            }
          }
          &-tbody {
            & > tr {
              & > td {
                border-bottom: none;
              }
              &:nth-child(odd) {
                background: @white03;
                @{antt}{
                  &-cell-fix-left,
                  &-cell-fix-right{
                    background: @white03;
                  }
                }
              }
              &:hover{
                @{antt}{
                  &-cell-fix-left,
                  &-cell-fix-right{
                    background: #edf6ff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &-option{
    background: @white01;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    margin-top: 20px;
    border-top: solid 1px @clear-black02;
    position: sticky;
    bottom: 0;
    z-index: 5;
  }
}
</style>
<template>
  <div>
    <a-row type="flex" justify="end" :gutter="[0, 10]">
      <a-space align="center">
        <a-popover
          placement="bottom"
          trigger="hover"
          overlayClassName="field-popover"
        >
          <template #content>
            <a-list size="small" :data-source="fileFormats">
              <template #renderItem="{ item }">
                <a-list-item @click="handleExport(item)">
                  <span class="name">{{ item }}</span>
                </a-list-item>
              </template>
            </a-list>
          </template>

          <a-button type="link" shape="round" size="small">
            <template #icon>
              <UploadOutlined />
            </template>
            エクスポート
          </a-button>
        </a-popover>

        <a-tooltip title="インポート">
          <a-upload
            name="file"
            :showUploadList="false"
            :before-upload="handleImport"
          >
            <a-button type="link" shape="round" size="small">
              <template #icon>
                <DownloadOutlined />
              </template>
              インポート
            </a-button>
          </a-upload>
        </a-tooltip>
      </a-space>
      <a-col :span="24">
        <a-table
          class="contact-table"
          :columns="columns"
          :data-source="contacts"
          :pagination="false"
          :scroll="{ x: 'max-content' }"
        >
          <template v-slot:prefecture="{ text }">
            {{ $t(`enums.prefecture.${text}`) }}
          </template>
          <template v-slot:boolean="{ text }">
            <a-checkbox :checked="text"></a-checkbox>
          </template>
          <template v-slot:actions="{ record }">
            <a-space>
              <a-button type="link" @click="handleEdit(record)">編集</a-button>
              <a-button
                type="link"
                danger
                @click="handleRemove(record)"
                :lonading="removeLoading"
                >削除</a-button
              >
            </a-space>
          </template>
        </a-table>
        <div class="contact-option">
          <a-button
            class="add-button"
            type="primary"
            size="large"
            @click="handleAdd"
            >新規連絡先 を追加</a-button
          >
          <a-pagination
            :current="1"
            :defaultPageSize="10"
            :total="10"
            show-less-items
          />
        </div>
      </a-col>
    </a-row>

    <a-modal
      v-model:visible="visibleAdd"
      width="800px"
      :title="$t('activerecord.models.client_contact')"
      @ok="handlCreate"
      @cancel="handleCancel"
    >
      <AddressForm :model="clientContact" :disabled="false"></AddressForm>
      <ul>
        <li
          v-for="message in errorMessages"
          class="error-message"
          :key="message"
        >
          {{ message }}
        </li>
      </ul>
    </a-modal>

    <a-modal
      v-model:visible="updateVisible"
      width="800px"
      :title="$t('activerecord.models.client_contact')"
      @ok="handleUpdate"
      @cancel="handleCancel"
    >
      <AddressForm :model="updateClientContact" :disabled="false"></AddressForm>
      <a-checkbox v-model:checked="updateClientContact.default"
        >デフォルト設定</a-checkbox
      >
      <ul>
        <li
          v-for="message in errorMessages"
          class="error-message"
          :key="message"
        >
          {{ message }}
        </li>
      </ul>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, ref } from "vue";
import AddressForm from "@/shared/components/AddressForm.vue";
import {
  Prefecture,
  CreateOneClientContactInput,
  UpdateOneClientContactInput,
  RemoveOneClientContactInput,
} from "@/client/schema";

import {
  useGetManyClientContactQuery,
  useCreateOneClientContactMutation,
  useUpdateOneClientContactMutation,
  useRemoveOneClientContactMutation,
} from "@/client/modules/api";
import { useResult } from "@vue/apollo-composable";
import { useI18n } from "../../../../shared/providors/i18n";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

import { getAccessToken } from "@/shared/providors/session";

export type Props = {
  clientUserGroupId: string;
};

// const fileFormats = ["csv", "xlsx"]; // エクセルファイルのエクスポートは未対応
const fileFormats = ["csv"];

export default defineComponent({
  components: {
    AddressForm,
    DownloadOutlined,
    UploadOutlined,
  },

  props: {
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const { ta } = useI18n();

    const state = reactive({
      clientContact: {} as CreateOneClientContactInput,
      updateClientContact: {} as UpdateOneClientContactInput,
      errorMessages: [] as string[],
    });

    const { result, refetch } = useGetManyClientContactQuery({
      clientUserGroupId: props.clientUserGroupId,
    });

    const contacts = useResult(result);

    const columns = [
      {
        key: "name",
        title: ta("name"),
        dataIndex: "name",
      },
      {
        key: "code",
        title: ta("code"),
        dataIndex: "code",
      },
      {
        key: "zipCode",
        dataIndex: "zipCode",
        title: ta("zipCode"),
      },
      {
        key: "prefecture",
        dataIndex: "prefecture",
        title: ta("prefecture"),
        slots: {
          customRender: "prefecture",
        },
      },
      {
        key: "city",
        dataIndex: "city",
        title: ta("city"),
      },
      {
        key: "block",
        dataIndex: "block",
        title: ta("block"),
      },
      {
        key: "building",
        dataIndex: "building",
        title: ta("building"),
      },
      {
        key: "tel",
        dataIndex: "tel",
        title: ta("tel"),
      },
      {
        key: "fax",
        dataIndex: "fax",
        title: ta("fax"),
      },
      {
        key: "default",
        dataIndex: "default",
        title: ta("default"),
        slots: {
          customRender: "boolean",
        },
      },
      {
        key: "actions",
        title: "アクション",
        fixed: "right",
        slots: {
          customRender: "actions",
        },
      },
    ];

    const handleCancel = () => {
      state.errorMessages = [];
    };

    const createClientContact = () => {
      const { mutate, error, onError, onDone } =
        useCreateOneClientContactMutation({});

      const visibleAdd = ref<boolean>(false);
      const handleAdd = async () => {
        state.clientContact = {
          clientUserGroupId: props.clientUserGroupId,
          name: "",
          zipCode: "",
          prefecture: Prefecture.Tokyo,
          city: "",
          block: "",
          building: "",
          tel: "",
          fax: "",
          default: false,
        };
        visibleAdd.value = true;
      };

      const handlCreate = async () => {
        await mutate({
          input: {
            clientUserGroupId: props.clientUserGroupId,
            name: state.clientContact.name,
            zipCode: state.clientContact.zipCode,
            prefecture: state.clientContact.prefecture,
            city: state.clientContact.city,
            block: state.clientContact.block,
            building: state.clientContact.building,
            tel: state.clientContact.tel,
            fax: state.clientContact.fax,
            default: state.clientContact.default,
          },
        });
      };

      onDone(() => {
        refetch();
        visibleAdd.value = false;
        handleCancel();
      });

      onError(() => {
        state.errorMessages = error.value.message.split(",");
      });

      return {
        visibleAdd,
        handleAdd,
        handlCreate,
      };
    };

    const updateClientContact = () => {
      const updateVisible = ref<boolean>(false);
      const {
        mutate: updateOneClientContact,
        error: updateError,
        onError: updateOnError,
        onDone: updateOnDone,
      } = useUpdateOneClientContactMutation({});

      const handleUpdate = async () => {
        await updateOneClientContact({
          input: {
            id: state.updateClientContact.id,
            clientUserGroupId: state.updateClientContact.clientUserGroupId,
            name: state.updateClientContact.name,
            zipCode: state.updateClientContact.zipCode,
            prefecture: state.updateClientContact.prefecture,
            city: state.updateClientContact.city,
            block: state.updateClientContact.block,
            building: state.updateClientContact.building,
            tel: state.updateClientContact.tel,
            fax: state.updateClientContact.fax,
            default: state.updateClientContact.default,
          },
        });
      };

      const handleEdit = async (contact: UpdateOneClientContactInput) => {
        state.updateClientContact = {
          id: contact.id,
          clientUserGroupId: contact.clientUserGroupId,
          name: contact.name,
          zipCode: contact.zipCode,
          prefecture: contact.prefecture,
          city: contact.city,
          block: contact.block,
          building: contact.building,
          tel: contact.tel,
          fax: contact.fax,
          default: contact.default,
        };
        updateVisible.value = true;
      };

      updateOnDone(() => {
        refetch();
        updateVisible.value = false;
      });

      updateOnError(() => {
        state.errorMessages = updateError.value.message.split(",");
      });

      return {
        updateVisible,
        handleUpdate,
        handleEdit,
      };
    };

    const removeClientContact = () => {
      const {
        mutate: removeOneClientContact,
        loading: removeLoading,
        error: removeError,
        onError: removeOnError,
        onDone: removeOnDone,
      } = useRemoveOneClientContactMutation({});

      const handleRemove = async (contact: RemoveOneClientContactInput) => {
        await removeOneClientContact({
          input: {
            id: contact.id,
          },
        });
      };

      removeOnDone(() => {
        refetch();
      });

      removeOnError(() => {
        state.errorMessages = removeError.value.message.split(",");
      });

      return {
        handleRemove,
        removeLoading,
      };
    };
    const handleExport = async (fileFormat: string) => {
      try {
        const exportEndpoint = `/api/app/client_contact/export/${props.clientUserGroupId}.${fileFormat}`;
        const accessToken = getAccessToken("client");
        await fetch(exportEndpoint, {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            const hour = ("0" + date.getHours()).slice(-2);
            const minute = ("0" + date.getMinutes()).slice(-2);
            const dateTime = `${year}${month}${day}_${hour}${minute}`;
            const fileName = `スタッフ連絡先_${dateTime}_.${fileFormat}`;
            anchor.download = fileName;
            anchor.click();
          });
      } catch (err) {
        console.log("err", err);
        message.error("エラーが発生し、ダウンロードできませんでした。");
        Object.keys(err).forEach((key) => {
          message.error(err[key]);
        });
      }
    };

    const handleImport = async (file: any) => {
      try {
        const importEndpoint = `/api/app/client_contact/import/${props.clientUserGroupId}.csv`;
        const accessToken = getAccessToken("client");
        const formData = new FormData();
        formData.append("file", file);

        await fetch(importEndpoint, {
          method: "POST",
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
          // headers: {
          //   'Content-Type': 'multipart/form-data',
          // },
          body: formData,
        });
        await refetch();
        message.success("アップロードしました。");
      } catch (err) {
        console.log("err", err);
        message.error("エラーが発生し、アップロードできませんでした。");
        Object.keys(err).forEach((key) => {
          message.error(err[key]);
        });
      }
    };

    return {
      ...toRefs(state),
      fileFormats,
      contacts,
      columns,
      ...createClientContact(),
      ...updateClientContact(),
      ...removeClientContact(),
      handleExport,
      handleImport,
      handleCancel,
    };
  },
});
</script>
