
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  toRefs,
  unref,
} from "vue";
import { useResult } from "@vue/apollo-composable";
import { message } from "ant-design-vue";
import { UploadOutlined } from "@ant-design/icons-vue";
import { assert } from "@/generic";
import {
  GetManyClientStorageFileQuery,
  ClientStorageFile,
  ClientUserGroup,
} from "@/client/schema";
import {
  useGetManyClientStorageFileQuery,
  useRemoveOneClientStorageFileMutation,
} from "@/client/modules/api";
import { useUploadResource } from "@/client/modules/uploadResource";

export type Props = {
  clientUserGroup?: ClientUserGroup;
};

interface sizeObj {
  size: number;
  suffix: string;
}

export default defineComponent({
  components: {
    UploadOutlined,
  },
  props: {
    clientUserGroup: {
      type: Object as PropType<ClientUserGroup>,
      required: false,
    },
  },
  setup(props: Props) {
    const pagination = reactive({
      currentPage: 1,
      pageSize: 8,
    });

    const {
      result: filesResult,
      loading,
      refetch,
      variables,
    } = useGetManyClientStorageFileQuery({
      clientUserGroupId: props.clientUserGroup?.id,
      offset: 0,
      limit: pagination.pageSize,
    });

    const storageFiles =
      useResult<GetManyClientStorageFileQuery, "clientStorageFiles">(
        filesResult
      );

    // TODO:total取得専用のqueryを作る
    const { result: totalResult, refetch: totalRefetch } =
      useGetManyClientStorageFileQuery({
        clientUserGroupId: props.clientUserGroup?.id,
      });

    const total = computed(() => {
      const result =
        useResult<GetManyClientStorageFileQuery, "clientStorageFiles">(
          totalResult
        );
      return unref(result)?.length;
    });

    const handlePageChange = (page: number) => {
      pagination.currentPage = page;
      variables.value = {
        clientUserGroupId: props.clientUserGroup?.id,
        offset: (page - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      };
    };

    const useUploadStorageFile = () => {
      const { uploadData, errorMessages: importErrorMessages } =
        useUploadResource();

      const state = reactive({
        visibleUpdloadModal: false,
        newUpload: {} as {
          name?: string;
          note?: string;
          file?: File;
        },
        fileList: [] as File[],
      });

      const canUpload = computed(() => state.newUpload?.file != null);

      const handleNew = () => {
        state.visibleUpdloadModal = true;
      };

      const handleNewCancel = () => {
        state.visibleUpdloadModal = false;
        state.newUpload = {};
        state.fileList = [];
      };

      const handleFileSelet = (file: File) => {
        state.newUpload.file = file;
        state.newUpload.name = file.name;
        return false;
      };

      const handleUpload = () => {
        const file = state.newUpload.file;
        assert(file != null);
        const fileExt = file.name.split(".").pop();

        uploadData(null, "ClientStorageFile", file, fileExt, {
          clientUserGroupId: props.clientUserGroup?.id,
          name: state.newUpload.name,
          note: state.newUpload.note,
        }).then(async (result: boolean) => {
          // 非同期に呼び出したいのでawaitにしないこと
          if (result) {
            await refetch();
            await totalRefetch();
            message.success("アップロードしました。");
          } else {
            const errorMessages = unref(importErrorMessages);
            Object.keys(errorMessages).forEach((key) => {
              message.error(errorMessages[key]);
            });
          }
          handleNewCancel();
        });
      };

      return {
        ...toRefs(state),
        canUpload,
        handleNew,
        handleNewCancel,
        handleUpload,
        handleFileSelet,
      };
    };

    const useRemoveClientStorageFile = () => {
      const { mutate } = useRemoveOneClientStorageFileMutation({});

      const handleRemove = async (clientStorageFile: ClientStorageFile) => {
        await mutate({
          input: { id: clientStorageFile.id },
        });
        await refetch();
        await totalRefetch();
        message.success(`削除しました。`);
      };

      return {
        handleRemove,
      };
    };

    const getSize = (size: number) => {
      const kb = 1024;
      const mb = Math.pow(kb, 2);
      const gb = Math.pow(kb, 3);
      let byteData: sizeObj = {
        size: size,
        suffix: "byte",
      };
      if (size >= gb) {
        byteData = {
          size: size / gb,
          suffix: "GB",
        };
      }
      if (size >= mb) {
        byteData = {
          size: size / mb,
          suffix: "MB",
        };
      }
      if (size >= kb) {
        byteData = {
          size: size / kb,
          suffix: "KB",
        };
      }
      const byte =
        Math.round(byteData.size).toString().toLocaleString() + byteData.suffix;

      return byte;
    };

    return {
      ...toRefs(pagination),
      storageFiles,
      total,
      loading,
      handlePageChange,
      ...useUploadStorageFile(),
      ...useRemoveClientStorageFile(),
      getSize,
    };
  },
});
