<template>
  <a-card :title="$tr('orderContact')">
    <template v-slot:extra>
      <a-space>
        <a-popover title="選択してください" trigger="click">
          <template #content>
            <a-list size="small" :data-source="clientContactOptions">
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-button
                    type="link"
                    @click="handleSelect(orderContact, item.value)"
                    >{{ item.label }}</a-button
                  >
                </a-list-item>
              </template>
            </a-list>
          </template>
          <a-button size="large" class="btn-blue"
            >グループの連絡先から入力</a-button
          >
        </a-popover>
        <template v-if="deletable">
          <a-button size="large" @click="handleRemove">削除</a-button>
        </template>
      </a-space>
    </template>
    <ul v-if="errorMessages.length">
      <li v-for="message in errorMessages" class="error-message" :key="message">
        {{ message }}
      </li>
    </ul>
    <AddressForm :model="orderContact" @blur="handleUpdate"></AddressForm>
  </a-card>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  PropType,
  unref,
  SetupContext,
} from "vue";

import { clone, parseError } from "@/generic";
import {
  useUpdateOneOrderContactMutation,
  useRemoveOneOrderContactMutation,
} from "@/client/modules/api";
import { userSelectClientContacts } from "./module";
import { Order, OrderContact, ClientContact } from "@/client/schema";
import AddressForm from "@/shared/components/AddressForm.vue";

export type Props = {
  order: Order;
  orderContact: OrderContact;
  deletable: boolean;
};

export default defineComponent({
  components: {
    AddressForm,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    orderContact: {
      type: Object as PropType<OrderContact>,
      required: true,
    },
    deletable: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["change"],
  setup(props: Props, context: SetupContext) {
    const state = reactive({
      errorMessages: [] as string[],
    });

    const orderContact = computed(() => clone(props.orderContact));

    const { mutate: update, error: updateError } =
      useUpdateOneOrderContactMutation({});
    const { mutate: remove, error: removeError } =
      useRemoveOneOrderContactMutation({});

    const handleUpdate = async () => {
      const safeOrderContact = unref(orderContact);
      try {
        state.errorMessages = [];
        await update({
          input: {
            id: safeOrderContact.id,
            name: safeOrderContact.name,
            zipCode: safeOrderContact.zipCode,
            prefecture: safeOrderContact.prefecture,
            city: safeOrderContact.city,
            block: safeOrderContact.block,
            building: safeOrderContact.building,
            tel: safeOrderContact.tel,
            fax: safeOrderContact.fax,
          },
        });
      } catch (e) {
        if (updateError.value == null) throw e;
        state.errorMessages = parseError(updateError.value);
      }
    };

    const handleRemove = async () => {
      try {
        state.errorMessages = [];
        await remove({
          input: {
            id: unref(orderContact).id,
          },
        });

        context.emit("change");
      } catch (e) {
        if (removeError.value == null) throw e;
        state.errorMessages = parseError(removeError.value);
      }
    };

    const { clientContactOptions, handleSelectContact } =
      userSelectClientContacts(props.order.clientUserGroup.id);
    const handleSelect = async (
      orderContact: OrderContact,
      clientContact: ClientContact
    ) => {
      await handleSelectContact(orderContact, clientContact);
      handleUpdate();
    };

    return {
      ...toRefs(state),
      clientContactOptions,
      handleSelect,
      handleUpdate,
      handleRemove,
    };
  },
});
</script>
