<style lang="less" scoped>
/deep/ .ant-input[disabled] {
  background: none;
  color: inherit;
  border: none;
}
/deep/
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background: none;
  color: inherit;
  border: none;
}
.ant-input-group {
  .address-code {
    display: flex;
    &-autoinput {
      margin-left: -15px;
      padding-left: 0 !important;
    }
  }
}
</style>
<template>
  <a-form :model="addressModel" layout="vertical">
    <a-row type="flex" justify="start" :gutter="[30, 15]">
      <a-col :span="24">
        <a-form-item :label="$t('attributes.zip_code')">
          <a-input-group compact>
            <a-row
              class="address-code"
              type="flex"
              justify="start"
              :gutter="[30, 15]"
            >
              <a-col class="address-code-zip" :span="5">
                <InputZipCode
                  v-model:value="addressModel.zipCode"
                  :disabled="inputDisabled"
                  @blur="handleBlur"
                  palaceholder="入力してください"
                />
              </a-col>
              <a-col class="address-code-autoinput">
                <a-button type="link" @click="handleSearch"
                  >郵便番号から自動入力</a-button
                >
              </a-col>
            </a-row>
          </a-input-group>
        </a-form-item>
      </a-col>
      <a-col :span="5">
        <a-form-item :label="$t('attributes.prefecture')">
          <a-select
            v-model:value="addressModel.prefecture"
            :options="
              prefectures.map((val) => ({
                value: val,
                label: $t(`enums.prefecture.${val}`),
              }))
            "
            :disabled="inputDisabled"
            @change="handleBlur"
            palaceholder="選択してください"
          ></a-select>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item :label="$t('attributes.city')">
          <a-input
            v-model:value="addressModel.city"
            :disabled="inputDisabled"
            @blur="handleBlur"
            palaceholder="入力してください"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :label="$t('attributes.block')">
          <a-input
            v-model:value="addressModel.block"
            :disabled="inputDisabled"
            @blur="handleBlur"
            palaceholder="入力してください"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :label="$t('attributes.building')">
          <a-input
            v-model:value="addressModel.building"
            :disabled="inputDisabled"
            @blur="handleBlur"
            palaceholder="入力してください"
          />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item :label="$t('attributes.name')">
          <a-input
            v-model:value="addressModel.name"
            :disabled="inputDisabled"
            @blur="handleBlur"
            palaceholder="入力してください"
          />
        </a-form-item>
      </a-col>
      <a-col :span="8" v-if="visibleShipperName">
        <a-form-item :label="$t('attributes.shipper_name')">
          <a-input
            v-model:value="addressModel.shipperName"
            :disabled="inputDisabled"
            @blur="handleBlur"
            palaceholder="入力してください"
          />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item :label="$t('attributes.tel')">
          <a-input
            v-model:value="addressModel.tel"
            :disabled="inputDisabled"
            @blur="handleBlur"
            palaceholder="入力してください"
          />
        </a-form-item>
      </a-col>
      <a-col :span="8" v-if="fax">
        <a-form-item :label="$t('attributes.fax')">
          <a-input
            v-model:value="addressModel.fax"
            :disabled="inputDisabled"
            @blur="handleBlur"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script lang="ts">
import { defineComponent, computed, unref, SetupContext } from "vue";
import { Prefecture } from "@/client/schema";

import { InputZipCode } from "@/generic";
import { usePostalCode } from "@/shared/modules/postalCode";

type Props = {
  model: any;
  disabled: boolean;
  fax: boolean;
  visibleShipperName: boolean;
};

export default defineComponent({
  components: {
    InputZipCode,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    fax: {
      type: Boolean,
      required: false,
      default: true,
    },
    visibleShipperName: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["blur"],
  setup(props: Props, context: SetupContext) {
    const { searchAddress } = usePostalCode();

    const prefectures = Object.values(Prefecture);
    const addressModel = computed(() => {
      return props.model;
    });
    const inputDisabled = computed(() => {
      return props.disabled;
    });

    const handleSearch = async () => {
      const _addressModel = unref(addressModel);
      if (_addressModel.zipCode == null || _addressModel.zipCode.length === 0)
        return;

      const result = await searchAddress(unref(addressModel).zipCode);

      if (result.length > 0) {
        const address = result[0];
        _addressModel.prefecture = prefectures[Number(address.prefCode) - 1];
        _addressModel.city = address.city;
        _addressModel.block = address.town;
        _addressModel.building = address.office || "";
      }

      handleBlur();
    };

    const handleBlur = () => {
      context.emit("blur");
    };

    return {
      prefectures,
      addressModel,
      inputDisabled,
      handleSearch,
      handleBlur,
    };
  },
});
</script>
