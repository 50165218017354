
import { defineComponent, reactive, toRefs, SetupContext, computed, unref } from "vue";
import { useResult } from "@vue/apollo-composable";
import { useRouter } from "vue-router";
import { parseError, assert, clone } from "@/generic";

import { useGetOneProductQuery } from "@/client/modules/api";
import { useI18n } from "@/shared/providors/i18n";
import { ProductPrice, Product } from "@/admin/schema";
import { useCreateOneOrderMutation } from "@/client/modules/api";
import { RightOutlined, LeftOutlined } from "@ant-design/icons-vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import { Currency, Number } from "@/generic/components/display";
import { InputNumber } from "@/generic/components/entry";

import moment from "moment";
import "moment/locale/ja";
moment.locale("ja");

export type Props = {
  productId: string;
  clientUserGroupId: string;
  parentId?: string;
};

export default defineComponent({
  components: {
    RightOutlined,
    LeftOutlined,
    ImagePreview,
    Currency,
    Number,
    InputNumber,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    clientUserGroupId: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: false,
    },
  },
  setup(props: Props, { emit }: SetupContext) {
    const router = useRouter();

    const { ta } = useI18n();
    const { result, loading } = useGetOneProductQuery({
      id: props.productId,
    });

    const product = useResult(result);

    const priceColumns = [
      {
        title: ta("quantity"),
        dataIndex: "quantity",
        key: "quantity",
        width: 120,
      },
      {
        title: ta("price"),
        dataIndex: "price",
        key: "price",
        width: 120,
      },
      {
        title: ta("basicDueDate"),
        dataIndex: "basicDueDate",
        key: "basicDueDate",
        width: 120,
      },
      {
        title: "",
        slots: {
          customRender: "action",
        },
        width: 60,
      },
    ];

    const { mutate: craeteOneOrder, error } = useCreateOneOrderMutation({});

    const state = reactive({
      visibleNewModal: false,
      currentPrice: null as ProductPrice | null,
      quantity: null as number | null,
      errorMessages: null as string[] | null,
    });

    const price = computed(() => {
      const _product = unref(clone(product)) as Product;
      if (state.quantity == null || _product.unitPrice == null) return 0;

      if (_product.hasMinPrice &&  _product.minLot != null && state.quantity < _product.minLot) {
        return _product.minPrice;
      } else {
        return _product.unitPrice * state.quantity;
      }      
    });


    const handleNewOrderByProductPrice = (productPrice: ProductPrice) => {
      state.visibleNewModal = true;
      state.currentPrice = productPrice;
      state.errorMessages = null;
    };

    const handleNewOrderByQuantity = () => {
      state.visibleNewModal = true;
      state.errorMessages = null;
    };

    const handleCreteOrder = async () => {
      try {
        assert(state.currentPrice != null || state.quantity != null);
        const productPrice: ProductPrice | null = state.currentPrice;
        const quantity = state.quantity;
        const result = await craeteOneOrder({
          input: {
            clientUserGroupId: props.clientUserGroupId,
            productPriceId: productPrice?.id,
            productId: props.productId,
            quantity: quantity,
            parentId: props.parentId || null,
          },
        });

        state.visibleNewModal = false;

        const orderId = result?.data?.createOneOrder?.order?.id;
        if (orderId) {
          emit("closeModal");
          await router.push({
            name: "OrderEdit",
            params: {
              id: orderId,
            },
          });
        }
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error.value);
      }
    };

    return {
      ...toRefs(state),
      product,
      loading,
      priceColumns,
      price,
      handleNewOrderByProductPrice,
      handleNewOrderByQuantity,
      handleCreteOrder,
      moment,
    };
  },
});
