
import {
  defineComponent,
  SetupContext,
  reactive,
  toRefs,
  computed,
  PropType,
  unref,
  ref,
} from "vue";
import AddressForm from "@/shared/components/AddressForm.vue";
import { clone, parseError } from "@/generic";
import {
  useUpdateOneOrderDeliveryMutation,
  useRemoveOneOrderDeliveryMutation,
} from "@/client/modules/api";
import { userSelectClientDelivery } from "./module";
import { Order, OrderDelivery, ClientDelivery } from "@/client/schema";

export type Props = {
  order: Order;
  orderDelivery: OrderDelivery;
  deletable: boolean;
};

export default defineComponent({
  components: {
    AddressForm,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    orderDelivery: {
      type: Object as PropType<OrderDelivery>,
      required: true,
    },
    deletable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    const state = reactive({
      errorMessages: [] as string[],
    });

    const orderDelivery = computed(() => clone(props.orderDelivery));

    const quantity = ref(props.orderDelivery.quantity);

    const { mutate: update, error } = useUpdateOneOrderDeliveryMutation({});
    const { mutate: remove, error: removeError } =
      useRemoveOneOrderDeliveryMutation({});

    const handleUpdate = async () => {
      const safeOrderDelivery = unref(orderDelivery);
      try {
        state.errorMessages = [];
        await update({
          input: {
            id: safeOrderDelivery.id,
            name: safeOrderDelivery.name,
            zipCode: safeOrderDelivery.zipCode,
            prefecture: safeOrderDelivery.prefecture,
            city: safeOrderDelivery.city,
            block: safeOrderDelivery.block,
            building: safeOrderDelivery.building,
            tel: safeOrderDelivery.tel,
            fax: safeOrderDelivery.fax,
            shipperName: safeOrderDelivery.shipperName,
            quantity: quantity.value,
          },
        });
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error.value);
      }
    };

    const handleRemove = async () => {
      try {
        state.errorMessages = [];
        await remove({
          input: {
            id: unref(orderDelivery).id,
          },
        });

        context.emit("change");
      } catch (e) {
        if (removeError.value == null) throw e;
        state.errorMessages = parseError(removeError.value);
      }
    };

    const { clientDeliveryOptions, handleSelectDelivery } =
      userSelectClientDelivery(props.order.clientUserGroup.id);
    const handleSelect = async (
      orderDelivery: OrderDelivery,
      clientDelivery: ClientDelivery
    ) => {
      await handleSelectDelivery(orderDelivery, clientDelivery);
      handleUpdate();
    };

    return {
      ...toRefs(state),
      clientDeliveryOptions,
      handleSelect,
      handleUpdate,
      handleRemove,
      quantity,
    };
  },
});
