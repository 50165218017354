<style lang="less" scoped>
@import "../../styles/variable.less";
.orderdetail {
  .detail-head {
    display: flex;
    align-items: center;
    & > * {
      flex: 0 1 auto;
    }

    &-title {
      width: 650px;
    }
    &-btn {
      width: calc(100% - 710px);
      display: flex;
      justify-content: right;
    }
  }

  &-content01 {
    display: flex;
    align-items: flex-start;
    & > * {
      flex: 1 1 auto;
    }
    .content01 {
      &-info {
        overflow: hidden;
      }
      &-row {
        & + .content01-row {
          margin-top: 20px;
          padding-top: 20px;
          border-top: solid 1px @clear-black02;
        }
      }
      &-col {
        border-left: solid 1px @clear-black02;
      }
      &-label {
        color: @clear-black03;
        font-size: 10px;
      }
      &-data {
        margin-bottom: 0;
        &.bold {
          font-weight: bold;
        }
      }
      &-upload {
        width: 100%;
        &-head {
          width: 1px;
          padding-right: 5px;
          white-space: nowrap;
          font-weight: bold;
        }
      }
    }
  }
  &-content02 {
    padding: 20px;
    margin-top: 40px;
    border: solid 1px @clear-black02;
    .content02 {
      &-head {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: solid 1px @clear-black02;
        &-title {
          font-size: 14px;
          font-weight: bold;
          margin: 0 auto 0 0;
        }
      }
      &-deliver-savebtn,
      &-contact-savebtn {
        margin-left: 10px;
      }
      &-table {
        width: 100%;
      }
      &-cell {
        padding: 20px;
        vertical-align: top;
        &inner {
          padding-left: 20px;
          border-left: solid 1px @clear-black02;
          &.nowrap {
            white-space: nowrap;
          }
        }
        &.head {
          width: 120px;
          padding: 20px 0;
        }
        &.data {
          border-bottom: solid 1px @clear-black02;
          &:first-of-type {
            .content02-cellinner {
              padding-left: 0;
              border-left: none;
            }
          }
        }
      }
      &-label {
        color: @clear-black03;
        font-size: 10px;
      }
      &-data {
        margin: 0;
        & + .content02-data {
          margin-top: 10px;
        }
      }
    }
  }

  .summary {
    /deep/ .ant-card {
      margin-top: 30px;
      padding: 0 20px 20px;
      border: solid 1px @clear-black02;
      &-head {
        padding: 0;
        &-title {
          font-size: 14px;
          font-weight: bold;
        }
      }
      &-body {
        padding: 20px 0 0;
      }
    }

    &-table {
      width: 100%;
    }
    &-head,
    &-data {
      vertical-align: baseline;
      padding: 16px 0;
    }
    &-head {
      width: 1px;
      padding-right: 60px;
      white-space: nowrap;
      &-data {
        font-weight: normal;
      }
    }
    &-data {
      padding-left: 20px;
      border-bottom: solid 1px @clear-black02;
      & > * {
        margin-bottom: 10px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
</style>
<template>
  <div class="orderdetail" v-if="order">
    <div class="detail-head">
      <a-button class="detail-head-back">
        <router-link to="/orders">
          <LeftOutlined />
        </router-link>
      </a-button>
      <div class="detail-head-title">
        <div v-if="order.parent">
          <router-link
            :to="{ name: 'OrderDetail', params: { id: order.parent.id } }"
            >{{ order.parent.name }}</router-link
          >
          >
        </div>
        <h2 class="detail-head-name" v-if="order">{{ order.product.name }}</h2>
      </div>
      <div class="detail-head-btn" v-if="order">
        <a-space>
          <a-button
            v-if="
              order.applicationStatus !== 'approved' &&
              order.applicationStatus !== 'canceled' &&
              !order.parent
            "
            size="large"
            @click="handleCancel"
            >取り消し</a-button
          >
          <div v-if="order.applicationStatus === 'rejected'">
            <a-button
              v-if="!order.parent"
              size="large"
              type="primary"
              @click="handleReorder"
              >再注文</a-button
            >
            <router-link
              v-else
              :to="{ name: 'OrderDetail', params: { id: order.parent.id } }"
              ><a-button size="large" type="primary"
                >親注文に戻って再注文</a-button
              >
            </router-link>
          </div>
          <a-button size="large" @click="handleCopy" v-if="!order.parent"
            >この内容でもう一度注文</a-button
          >
          <a-button
            v-if="order.orderNo === null"
            size="large"
            type="primary"
            @click="handleUploaded"
            :disabled="!order.uploaded"
          >
            入稿完了
          </a-button>
        </a-space>
      </div>
    </div>

    <div class="orderdetail-content01" v-if="order">
      <template v-if="order.product.thumbnailPath">
        <ImagePreview :preview="order.product" />
      </template>
      <template v-else>
        <ImagePreview :fallback="true" />
      </template>
      <div class="content01-info">
        <a-row class="content01-row" :gutter="[32, 16]">
          <a-col class="content01-col">
            <p class="content01-label">注文番号</p>
            <p class="content01-data">{{ order.orderNo }}</p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">商品名</p>
            <p class="content01-data">
              {{ order.product.name }}
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">注文者</p>
            <p class="content01-data">
              {{ order.clientUser.name }}
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">注文状況</p>
            <p class="content01-data">
              {{
                order.orderStatus != null ? order.orderStatus.name : "承認待ち"
              }}
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">承認状況</p>
            <p class="content01-data">
              {{
                $t(`enums.application_status_type.${order.applicationStatus}`)
              }}
            </p>
          </a-col>
          <a-col
            class="content01-col"
            v-if="order.orderProduct.productType == 'draft'"
          >
            <p class="content01-label">入稿状況</p>
            <p class="content01-data">
              <template v-if="order.uploaded">
                <span class="bold">入稿済み</span>
              </template>
              <template v-else>
                <span class="bold">未入稿</span>
              </template>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">配送状況</p>
            <p class="content01-data">
              <template v-if="order.delivered">
                <span class="bold">配送済み</span>
              </template>
              <template v-else>
                <span class="bold">未配送</span>
              </template>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">注文日</p>
            <p class="content01-data">{{ formatTimestamp(order.orderedAt) }}</p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">納品日</p>
            <p class="content01-data">
              {{ formatTimestamp(order.deliveryAt) }}
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">スタッフグループ</p>
            <p class="content01-data">
              {{ order.clientUserGroup.name }}
            </p>
          </a-col>
        </a-row>
        <a-row class="content01-row" :gutter="[32, 16]">
          <a-col class="content01-col">
            <p class="content01-label">部数</p>
            <p class="content01-data bold">
              <Number :value="order.orderProduct.quantity"></Number>部
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">金額</p>
            <p class="content01-data bold">
              <Currency :value="order.orderProduct.price"></Currency>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">合計金額</p>
            <p class="content01-data bold">
              <Currency :value="order.amount"></Currency>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-data">
              <TypesettingButton
                v-if="order.orderProduct.productType == 'typesetting_tag'"
                :typesettingParamId="order.orderProduct.typesettingParam.id"
                :previewOnly="true"
                @change="handleChange"
              ></TypesettingButton>
              <OuterCartonTypesettingButton
                v-if="order.orderProduct.productType == 'outer_carton_label'"
                :outerCartonParamId="order.orderProduct.outerCartonParam.id"
                :previewOnly="true"
                @change="handleChange"
              ></OuterCartonTypesettingButton>
              <InnerCartonParamPreviewButton
                v-if="order.orderProduct.productType == 'inner_carton_label'"
                :innerCartonParam="order.orderProduct.innerCartonParam"
              ></InnerCartonParamPreviewButton>
              <PriceLabelParamPreviewButton
                v-if="order.orderProduct.productType == 'price_label'"
                :priceLabelParam="order.orderProduct.priceLabelParam"
              ></PriceLabelParamPreviewButton>
              <UploadButton
                v-if="order.orderProduct.productType == 'draft'"
                :order="order"
                @change="handleChange"
              ></UploadButton>
            </p>
          </a-col>
        </a-row>
        <a-row
          class="content01-row"
          :gutter="[32, 16]"
          v-if="order.orderProduct.files.length"
        >
          <a-col class="content01-col">
            <table class="content01-upload">
              <th class="content01-upload-head">入稿データ：</th>
              <td class="content01-upload-data">
                <UploadFileList :files="order.orderProduct.files" />
              </td>
            </table>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="orderdetail-content02">
      <div class="content02-head">
        <h3 class="content02-head-title">注文内容</h3>
        <!-- <a-button class="content02-deliver-savebtn" disabled size="large"
          >配送先をマスターに保存</a-button
        >
        <a-button class="content02-contact-savebtn" disabled size="large"
          >連絡先をマスターに保存</a-button
        > -->
      </div>
      <table class="content02-table" v-if="order">
        <tr class="content02-row">
          <th class="content02-cell head">
            <p class="content02-title">製品名設定</p>
          </th>
          <td class="content02-cell data" colspan="2">
            <div class="content02-cellinner">
              <p class="content02-data">
                {{ order.orderProduct.name }}
              </p>
            </div>
          </td>
        </tr>
        <template
          v-for="orderDelivery in order.orderDeliveries"
          :key="orderDelivery.id"
        >
          <tr class="content02-row">
            <th class="content02-cell head">
              <p class="content02-title">注文配送先</p>
            </th>
            <td class="content02-cell data">
              <div class="content02-cellinner">
                <p class="content02-data">
                  {{ orderDelivery.address }}
                </p>
                <p class="content02-data">
                  {{ orderDelivery.name }}
                  {{
                    orderDelivery.shipperName
                      ? " 荷主：" + orderDelivery.shipperName
                      : ""
                  }}
                  TEL : {{ orderDelivery.tel }}
                </p>
              </div>
            </td>
            <td class="content02-cell data">
              <div class="content02-cellinner nowrap">
                <p class="content02-label">部数</p>
                <p class="content02-data">
                  <Number :value="orderDelivery.quantity"></Number>部
                </p>
              </div>
            </td>
          </tr>
        </template>
      </table>
      <table class="content02-table" v-if="order">
        <tr
          class="content02-row"
          v-for="contact in order.orderContacts"
          :key="contact.id"
        >
          <th class="content02-cell head">
            <p class="content02-title">注文連絡先</p>
          </th>
          <td class="content02-cell data" colspan="2">
            <div class="content02-cellinner">
              <p class="content02-data">
                {{ contact.address }}
              </p>
              <p class="content02-data">
                {{ contact.name }}
                TEL : {{ contact.tel }} FAX : {{ contact.fax }}
              </p>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="summary" v-if="order.children.length">
      <a-card title="注文合計" bordered>
        <table class="summary-table">
          <tr class="summary-row">
            <th class="summary-head">
              <p class="summary-head-title">この注文</p>
            </th>
            <td class="summary-data">
              <p class="summary-person">
                {{ order.orderProduct.name }}
              </p>
            </td>
            <td class="summary-data">
              <p class="summary-person">
                <Currency :value="order.amount"></Currency>
              </p>
            </td>
          </tr>
          <tr
            class="summary-row"
            v-for="childOrder in order.children"
            :key="childOrder.id"
          >
            <th class="summary-head">
              <p class="summary-head-title">子注文</p>
            </th>
            <td class="summary-data">
              <p class="summary-person">
                <router-link
                  :to="{ name: 'OrderDetail', params: { id: childOrder.id } }"
                >
                  {{ childOrder.orderProduct.name }}
                </router-link>
              </p>
            </td>
            <td class="summary-data">
              <p class="summary-person">
                <Currency :value="childOrder.amount"></Currency>
              </p>
            </td>
          </tr>
          <tr class="summary-row">
            <th class="summary-head">
              <p class="summary-head-title">合計</p>
            </th>
            <td class="summary-data"></td>
            <td class="summary-data">
              <p class="summary-person">
                <Currency :value="order.setAmount"></Currency>
              </p>
            </td>
          </tr>
        </table>
      </a-card>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  useGetOneOrderQuery,
  useCancelOrderMutation,
  useCopyOneOrderMutation,
  useUpdateOneOrderMutation,
} from "@/client/modules/api";
import { useResult } from "@vue/apollo-composable";
import { formatTimestamp, Currency, Number } from "@/generic";
import { useRouter } from "vue-router";
import { LeftOutlined } from "@ant-design/icons-vue";
import UploadButton from "./UploadButton.vue";
import TypesettingButton from "./TypesettingButton.vue";
import OuterCartonTypesettingButton from "./OuterCartonTypesettingButton.vue";
import InnerCartonParamPreviewButton from "./InnerCartonParamPreviewButton.vue";
import PriceLabelParamPreviewButton from "./PriceLabelParamPreviewButton.vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import UploadFileList from "@/shared/components/UploadFileList.vue";

export type Props = {
  orderId: string;
};

export default defineComponent({
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  components: {
    UploadButton,
    TypesettingButton,
    OuterCartonTypesettingButton,
    InnerCartonParamPreviewButton,
    PriceLabelParamPreviewButton,
    Currency,
    Number,
    LeftOutlined,
    UploadFileList,
    ImagePreview,
  },
  setup(props: Props) {
    const router = useRouter();

    const { result, loading, refetch } = useGetOneOrderQuery(
      {
        id: props.orderId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const order = useResult(result);

    const useCancelOrder = () => {
      const { mutate: cancel } = useCancelOrderMutation({});
      const handleCancel = async () => {
        await cancel({
          input: {
            id: props.orderId,
          },
        });
      };
      return {
        handleCancel,
      };
    };

    const useReorderOrder = () => {
      const handleReorder = async () => {
        router.push({
          name: "OrderEdit",
          params: {
            id: props.orderId,
          },
        });
      };
      return {
        handleReorder,
      };
    };

    const useCopyOrder = () => {
      const { mutate: copyOne } = useCopyOneOrderMutation({});

      const handleCopy = async () => {
        const { data } = await copyOne({
          input: {
            id: props.orderId,
          },
        });
        const newOrderId = data?.copyOneOrder?.order?.id as string;
        router.push({
          name: "OrderEdit",
          params: {
            id: newOrderId,
          },
        });
      };

      return {
        handleCopy,
      };
    };

    const handleChange = async () => {
      await refetch();
    };

    const useUpdateOrder = () => {
      const { mutate: updateOneOrder } = useUpdateOneOrderMutation({});

      const handleUploaded = async () => {
        await updateOneOrder({
          input: {
            id: props.orderId,
            draft: false,
          },
        });
        await refetch();
      };

      return {
        handleUploaded,
      };
    };

    return {
      order,
      loading,
      ...useCancelOrder(),
      ...useReorderOrder(),
      ...useCopyOrder(),
      ...useUpdateOrder(),
      formatTimestamp,
      handleChange,
    };
  },
});
</script>
