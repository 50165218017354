
import { computed, defineComponent, reactive, toRefs, ref } from "vue";
import {
  useGetOneClientApplicationFlowQuery,
  useUpdateOneClientApplicationFlowMutation,
  useCreateOneClientApplicationFlowRouteMutation,
  useUpdateOneClientApplicationFlowRouteMutation,
  useRemoveOneClientApplicationFlowRouteMutation,
  useCreateOneClientApplicationFlowLevelMutation,
  useUpdateOneClientApplicationFlowLevelMutation,
  useRemoveOneClientApplicationFlowLevelMutation,
} from "@/client/modules/api";
import {
  ClientApplicationFlow,
  GetOneClientApplicationFlowQuery,
  ClientApplicationFlowLevel,
  ClientApplicationFlowRoute,
  ClientUser,
} from "@/client/schema";

import { assert, clone } from "@/generic";

import {
  LeftOutlined,
  PlusCircleOutlined,
  ForkOutlined,
} from "@ant-design/icons-vue";

type Props = {
  flowId: string;
  clientUserGroupId: string;
};

export default defineComponent({
  components: {
    LeftOutlined,
    PlusCircleOutlined,
    ForkOutlined,
  },
  props: {
    flowId: {
      type: String,
      required: true,
    },
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const { onResult, refetch } = useGetOneClientApplicationFlowQuery(
      {
        id: props.flowId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const state = reactive({
      flow: null as ClientApplicationFlow | null,
      alertVisible: false,
      invalidColumnNames: [] as string[],
    });

    onResult(({ data }: { data: GetOneClientApplicationFlowQuery }) => {
      state.flow = clone(data.clientApplicationFlow as ClientApplicationFlow);
      state.flow?.routes.sort(
        (
          routeA: ClientApplicationFlowRoute,
          routeB: ClientApplicationFlowRoute
        ) => {
          if (routeA.id < routeB.id) return -1;
          if (routeA.id > routeB.id) return 1;
          return 0;
        }
      );
    });

    const columnCheck = () => {
      const flow = state.flow;
      state.invalidColumnNames = [];
      state.alertVisible = false;
      if (flow) {
        if (!flow.name) {
          state.invalidColumnNames.push("経路名");
          return (state.alertVisible = true);
        }
        if (flow.routes.length) {
          flow.routes.forEach((route, i) => {
            if (route.condLowerPrice === null) {
              state.invalidColumnNames.push(
                `承認経路${i + 1}の経路条件下限金額`
              );
              return (state.alertVisible = true);
            }
          });
        }
        // 承認経路0件エラーなどは、一旦表示しない
        // if (!flow.routes.length) {
        //   state.invalidColumnNames.push("経路分岐")
        //   return state.alertVisible = true;
        // } else {
        //   flow.routes.forEach((route, i) => {
        //     if (route.condLowerPrice === null) {
        //       state.invalidColumnNames.push(`承認経路${i+1}の経路条件下限金額`)
        //       return state.alertVisible = true;
        //     }
        //     if (!route.levels.length) {
        //       state.invalidColumnNames.push(`承認経路${i+1}の承認フロー`)
        //       return state.alertVisible = true;
        //     } else {
        //       route.levels.forEach((level, j) => {
        //         if (!level.userApprovers.length) {
        //           state.invalidColumnNames.push(`承認経路${i+1}の承認フロー${j+1}の承認者`)
        //           return state.alertVisible = true;
        //         }
        //       })
        //     }
        //   })
        // }
      }
    };

    const useUpdateFlow = () => {
      const { mutate } = useUpdateOneClientApplicationFlowMutation({});

      const handleUpdate = () => {
        columnCheck();
        if (!state.alertVisible) {
          assert(state.flow != null);
          mutate({
            input: {
              id: state.flow.id,
              name: state.flow.name,
            },
          });
        }
      };

      return {
        handleUpdate,
      };
    };

    const useCreateFlowRoute = () => {
      const { mutate } = useCreateOneClientApplicationFlowRouteMutation({});

      const handleCreateRoute = async () => {
        assert(state.flow != null);
        await mutate({
          input: {
            flowId: state.flow.id,
            condLowerPrice: 0,
          },
        });
        await refetch();
      };

      return {
        handleCreateRoute,
      };
    };

    const useUpdateFlowRoute = () => {
      const { mutate } = useUpdateOneClientApplicationFlowRouteMutation({});

      const handleUpdateRoute = async (
        clientApplicationFlowRoute: ClientApplicationFlowRoute
      ) => {
        columnCheck();
        if (!state.alertVisible) {
          await mutate({
            input: {
              id: clientApplicationFlowRoute.id,
              condLowerPrice: clientApplicationFlowRoute.condLowerPrice,
            },
          });
          await refetch();
        }
      };

      return {
        handleUpdateRoute,
      };
    };

    const useRemoveFlowRoute = () => {
      const { mutate } = useRemoveOneClientApplicationFlowRouteMutation({});

      const handleRemoveRoute = async (
        clientApplicationFlowRoute: ClientApplicationFlowRoute
      ) => {
        await mutate({
          input: {
            id: clientApplicationFlowRoute.id,
          },
        });
        await refetch();
      };

      return {
        handleRemoveRoute,
      };
    };

    const useCreateFlowLevel = () => {
      const { mutate } = useCreateOneClientApplicationFlowLevelMutation({});

      const handleCreateLevel = async (
        clientApplicationFlowRoute: ClientApplicationFlowRoute
      ) => {
        const level =
          clientApplicationFlowRoute.levels.length === 0
            ? 1
            : Math.max(
                ...clientApplicationFlowRoute.levels.map((level) => level.level)
              ) + 1;
        await mutate({
          input: {
            routeId: clientApplicationFlowRoute.id,
            level: level,
          },
        });
        await refetch();
      };

      return {
        handleCreateLevel,
      };
    };

    const useCreateFlowLevelApprover = () => {
      const { mutate } = useUpdateOneClientApplicationFlowLevelMutation({});

      const approverState = reactive({
        visibleClientUserModal: false,
        approver: null as ClientApplicationFlowLevel | null,
      });

      const currentClientApplicationFlowLevel = ref(
        null as ClientApplicationFlowLevel | null
      );

      const users = computed(() => {
        const clientApplicationFlowLevel =
          currentClientApplicationFlowLevel.value;
        if (clientApplicationFlowLevel == null) return [];
        const ids = clientApplicationFlowLevel.userApprovers.map(
          (approver) => approver.id
        );
        return state.flow?.clientUserGroup?.members.filter(
          (user) => !ids.includes(user.id)
        );
      });

      const handleNewFlowLevelApprover = (
        clientApplicationFlowLevel: ClientApplicationFlowLevel
      ) => {
        approverState.visibleClientUserModal = true;
        currentClientApplicationFlowLevel.value = clientApplicationFlowLevel;
      };

      const handleCreateFlowLevelApprover = async (clientUser: ClientUser) => {
        assert(currentClientApplicationFlowLevel.value != null);

        // 既に存在する場合
        if (
          currentClientApplicationFlowLevel.value.userApprovers.some(
            (approver: ClientUser) => approver.id === clientUser.id
          )
        )
          return;

        const newUserApproverIds = [
          clientUser.id,
          ...currentClientApplicationFlowLevel.value.userApprovers.map(
            (approver) => approver.id
          ),
        ];

        await mutate({
          input: {
            id: currentClientApplicationFlowLevel.value.id,
            userApproverIds: newUserApproverIds,
          },
        });
        await refetch();
        approverState.visibleClientUserModal = false;
      };

      const handleRemoveFlowLevelApprover = async (
        clientApplicationFlowLevel: ClientApplicationFlowLevel,
        clientUser: ClientUser
      ) => {
        const newUserApproverIds = [
          ...clientApplicationFlowLevel.userApprovers
            .filter((approver) => approver.id !== clientUser.id)
            .map((approver) => approver.id),
        ];

        await mutate({
          input: {
            id: clientApplicationFlowLevel.id,
            userApproverIds: newUserApproverIds,
          },
        });
        await refetch();
      };

      return {
        ...toRefs(approverState),
        users,
        handleNewFlowLevelApprover,
        handleCreateFlowLevelApprover,
        handleRemoveFlowLevelApprover,
      };
    };

    const useRemoveFlowLevel = () => {
      const { mutate } = useRemoveOneClientApplicationFlowLevelMutation({});

      const handleRemoveLevel = async (
        clientApplicationFlowLevel: ClientApplicationFlowLevel
      ) => {
        await mutate({
          input: {
            id: clientApplicationFlowLevel.id,
          },
        });
        await refetch();
      };

      return {
        handleRemoveLevel,
      };
    };

    // watch(
    //   () => state.flow,
    //   (val) => {
    //     state.invalidColumnNames = [];
    //     if (val) {
    //       console.log(val.name);
    //       if (!val.name) {
    //          state.invalidColumnNames.push("経路名")
    //         return state.alertVisible = true;
    //       }
    //       if (!val.routes.length) {
    //         state.invalidColumnNames.push("経路分岐")
    //         return state.alertVisible = true;
    //       } else {
    //         val.routes.forEach((route, i) => {
    //           if (route.condLowerPrice === null) {
    //             state.invalidColumnNames.push(`承認経路${i+1}の経路条件下限金額`)
    //             return state.alertVisible = true;
    //           }
    //           if (!route.levels.length) {
    //             state.invalidColumnNames.push(`承認経路${i+1}の承認フロー`)
    //             return state.alertVisible = true;
    //           } else {
    //             route.levels.forEach((level, j) => {
    //               if (!level.userApprovers.length) {
    //                 state.invalidColumnNames.push(`承認経路${i+1}の承認フロー${j+1}の承認者`)
    //                 return state.alertVisible = true;
    //               } else {
    //                 return state.alertVisible = false;
    //               }
    //             })
    //           }
    //         })
    //       }
    //     }
    //   }
    // );

    const handleClose = () => {
      state.alertVisible = false;
    };

    return {
      ...toRefs(state),
      ...useUpdateFlow(),
      ...useCreateFlowRoute(),
      ...useUpdateFlowRoute(),
      ...useRemoveFlowRoute(),
      ...useCreateFlowLevel(),
      ...useCreateFlowLevelApprover(),
      ...useRemoveFlowLevel(),
      handleClose,
    };
  },
});
