
import { defineComponent, unref } from "vue";
import { Content } from "@/generic";
import ClientUserGroupDetail from "@/client/components/settings/clientUserGroup/Detail.vue";
import ClientUserGroupNav from "@/client/components/settings/clientUserGroup/Nav.vue";
import ClientUserGroupMembershipList from "@/client/components/settings/clientUserGroupMembership/List.vue";
import ClientUserGroupProductList from "@/client/components/settings/clientUserGroupProduct/List.vue";
import ClientDeliveryDetail from "@/client/components/settings/clientDelivery/Detail.vue";
import ClientContactDetail from "@/client/components/settings/clientContact/Detail.vue";

import ApplicationFlowList from "@/client/components/settings/applicationFlow/List.vue";
import { useRoute, useRouter } from "vue-router";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    ClientUserGroupNav,
    ClientUserGroupDetail,
    ClientUserGroupMembershipList,
    ClientUserGroupProductList,
    ClientDeliveryDetail,
    ClientContactDetail,
    ApplicationFlowList,
    UserName,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: false,
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const handleChangeTab = (activeKey: string) => {
      const name = unref(route.name) || undefined;
      const params = {
        ...unref(route.params),
        tab: activeKey,
      };
      router.replace({
        name: name,
        params: params,
      });
    };

    return {
      handleChangeTab,
    };
  },
});
