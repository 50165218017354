
import { defineComponent, reactive, toRefs } from "vue";
import { default as SearchTool, Search } from "./SearchTool.vue";
import OrderList from "./OrderList.vue";
import { Content } from "@/generic";
import { SearchOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { useResult } from "@vue/apollo-composable";
import {
  useGetManyOrderQuery,
  useGetTotalOrderQuery,
  useGetAmountOrderQuery,
} from "@/client/modules/api";
import { getAccessToken } from "@/shared/providors/session";
import { Currency, Number } from "@/generic/components/display";

export type Props = {
  draftOnly: boolean;
};

export default defineComponent({
  components: {
    Content,
    SearchTool,
    OrderList,
    SearchOutlined,
    Currency,
    Number,
  },
  props: {
    draftOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props: Props) {
    const state = reactive({
      visibleSearchTool: false,
      currentPage: 1,
      pageSize: 10,
      search: {} as Search,
    });

    const { result, loading, variables } = useGetManyOrderQuery(
      {
        draftOnly: props.draftOnly,
        offset: 0,
        limit: state.pageSize,
      },
      {
        fetchPolicy: "cache-and-network",
      }
    );

    const { result: totalResult, variables: totalVariables } =
      useGetTotalOrderQuery(
        {
          draftOnly: props.draftOnly,
        },
        {
          fetchPolicy: "cache-and-network",
        }
      );

    const { result: amountResult, variables: amountVariables } =
      useGetAmountOrderQuery(
        {
          draftOnly: props.draftOnly,
        },
        {
          fetchPolicy: "cache-and-network",
        }
      );

    const orders = useResult(result);
    const total = useResult(totalResult);
    const amount = useResult(amountResult);

    const toggleSearch = () => {
      state.visibleSearchTool = !state.visibleSearchTool;
    };

    const handleSearch = (search: Search) => {
      state.search = search;
      variables.value = {
        ...state.search,
        draftOnly: props.draftOnly,
      };
      totalVariables.value = {
        ...state.search,
        draftOnly: props.draftOnly,
      };
      amountVariables.value = {
        ...state.search,
        draftOnly: props.draftOnly,
      };
    };

    const handlePageChange = (page: number) => {
      state.currentPage = page;
      variables.value = {
        ...state.search,
        draftOnly: props.draftOnly,
        offset: (page - 1) * state.pageSize,
        limit: state.pageSize,
      };
    };

    const handleExport = async () => {
      try {
        const variables = state.search;
        const params: { [key: string]: any } = {};
        params["draft_only"] = props.draftOnly;
        if (variables.clientUserId)
          params["client_user_id"] = variables.clientUserId;
        if (variables.orderedFrom)
          params["ordered_from"] = variables.orderedFrom;
        if (variables.orderedTo) params["ordered_to"] = variables.orderedTo;
        if (variables.orderNo) params["order_no"] = variables.orderNo;
        if (variables.productName)
          params["product_name"] = variables.productName;
        if (variables.orderStatusId)
          params["order_status_id"] = variables.orderStatusId;
        if (variables.productTagIds?.length)
          params["product_tag_ids"] = variables.productTagIds.toString();
        if (variables.productCategoryId)
          params["product_category_id"] = variables.productCategoryId;
        const query = new URLSearchParams(params);
        const exportEndpoint = `/api/app/order/export.csv?${query}`;
        const accessToken = getAccessToken("client");
        await fetch(exportEndpoint, {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            const hour = ("0" + date.getHours()).slice(-2);
            const minute = ("0" + date.getMinutes()).slice(-2);
            const dateTime = `${year}${month}${day}_${hour}${minute}`;
            const name = props.draftOnly ? "下書き" : "注文";
            const fileName = `${name}_${dateTime}_.csv`;
            anchor.download = fileName;
            anchor.click();
          });
      } catch (err) {
        console.log("err", err);
        Object.keys(err).forEach((key) => {
          message.error(err[key]);
        });
      }
    };

    return {
      ...toRefs(state),
      orders,
      total,
      amount,
      loading,
      toggleSearch,
      handleSearch,
      handlePageChange,
      handleExport,
    };
  },
});
