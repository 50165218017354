
import { defineComponent, reactive, ref, toRefs, unref } from "vue";
import { parseError, Currency, Number } from "@/generic";
// import AddressDisplay from "@/shared/components/AddressDisplay.vue";
import {
  useGetOneOrderQuery,
  useUpdateOneOrderMutation,
} from "@/client/modules/api";
import { useRouter } from "vue-router";
import { useResult } from "@vue/apollo-composable";
import { GetOneOrderQuery, Order } from "@/client/schema";
import { LeftOutlined } from "@ant-design/icons-vue";
import UploadButton from "./UploadButton.vue";
import TypesettingButton from "./TypesettingButton.vue";
import OuterCartonTypesettingButton from "./OuterCartonTypesettingButton.vue";
import InnerCartonParamPreviewButton from "./InnerCartonParamPreviewButton.vue";
import PriceLabelParamPreviewButton from "./PriceLabelParamPreviewButton.vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import moment from "moment";
import "moment/locale/ja";
moment.locale("ja");

export type Props = {
  orderId: string;
};

export default defineComponent({
  components: {
    Currency,
    Number,
    // AddressDisplay,
    LeftOutlined,
    TypesettingButton,
    OuterCartonTypesettingButton,
    InnerCartonParamPreviewButton,
    PriceLabelParamPreviewButton,
    UploadButton,
    ImagePreview,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const router = useRouter();

    const { loading, result, refetch } = useGetOneOrderQuery(
      {
        id: props.orderId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const state = reactive({
      errorMessages: [] as string[],
    });

    const order = useResult<GetOneOrderQuery, "order">(result);

    const { mutate: updateOneOrder, error } = useUpdateOneOrderMutation({});

    const handleOrder = async () => {
      try {
        const orderSafe = unref(order) as Order;
        await updateOneOrder({
          input: {
            id: orderSafe.id,
            draft: false,
          },
        });

        router.push({
          name: "OrderDetail",
          params: {
            id: orderSafe.id,
          },
        });
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error.value);
      }
    };

    const handleChange = async () => {
      await refetch();
    };

    const alertVisible = ref(true);
    const handleClose = () => {
      alertVisible.value = false;
    };

    const orderble = (order: Order) => {
      switch (order.orderProduct.productType) {
        case "typesetting_tag":
        case "outer_carton_label":
          if (!order.uploaded) return false;
          else return true;
        case "inner_carton_label": {
          const innerCartonParam = order.orderProduct.innerCartonParam;
          if (
            innerCartonParam == null ||
            innerCartonParam.janCode == null ||
            innerCartonParam.productName == null ||
            innerCartonParam.quantityPerInnerCartonText == null ||
            innerCartonParam.taxIncludedPrice == null
          )
            return false;
          else return true;
        }
        case "price_label": {
          const priceLabelParam = order.orderProduct.priceLabelParam;
          if (
            priceLabelParam == null ||
            priceLabelParam.janCode == null ||
            priceLabelParam.taxIncludedPrice == null
          )
            return false;
          else return true;
        }
        default:
          return true;
      }
    };

    const orderbleParent = () => {
      const orderSafe = unref(order) as Order;
      return orderSafe.children.every(orderble);
    };

    return {
      ...toRefs(state),
      order,
      loading,
      handleOrder,
      handleChange,
      moment,
      alertVisible,
      handleClose,
      orderble,
      orderbleParent,
    };
  },
});
