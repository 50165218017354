
import { defineComponent, reactive, toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import { assert, clone, parseError, Currency, Number } from "@/generic";
import {
  useGetOneOrderQuery,
  useUpdateOneOrderMutation,
  useRemoveOneOrderMutation,
} from "@/client/modules/api";
import { userSelectClientContacts, userSelectClientDelivery } from "./module";
import { Order, ProductType } from "@/client/schema";
import EditDeliveryList from "./EditDeliveryList.vue";
import EditContactList from "./EditContactList.vue";
import OrderCommentList from "@/client/components/orderComment/List.vue";
import UploadButton from "./UploadButton.vue";
import TypesettingButton from "./TypesettingButton.vue";
import OuterCartonTypesettingButton from "./OuterCartonTypesettingButton.vue";
import InnerCartonParamSettingButton from "./InnerCartonParamSettingButton.vue";
import PriceLabelParamSettingButton from "./PriceLabelParamSettingButton.vue";
import ProductList from "@/client/components/product/List.vue";
import ProductDetail from "@/client/components/product/Detail.vue";
import { LeftOutlined } from "@ant-design/icons-vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import { InputNumber } from "@/generic/components/entry";

import moment from "moment";
import "moment/locale/ja";

moment.locale("ja");

export type Props = {
  orderId: string;
  typesettingPreviewFlag: boolean;
};

export default defineComponent({
  components: {
    Currency,
    Number,
    EditDeliveryList,
    EditContactList,
    OrderCommentList,
    UploadButton,
    TypesettingButton,
    OuterCartonTypesettingButton,
    InnerCartonParamSettingButton,
    PriceLabelParamSettingButton,
    ProductList,
    ProductDetail,
    LeftOutlined,
    ImagePreview,
    InputNumber,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    typesettingPreviewFlag: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props: Props) {
    const router = useRouter();

    const { loading, onResult, refetch } = useGetOneOrderQuery(
      {
        id: props.orderId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const state = reactive({
      order: null as null | Order,
      errorMessages: [] as string[],
      productPriceId: null as null | string,
    });

    onResult((result) => {
      state.order = clone(result.data.order) as Order;
      state.productPriceId = state.order.orderProduct.productPrice?.id || null;
    });

    const isNormal = computed(
      () => state.order?.orderProduct?.productType === ProductType.Normal
    );

    const isDraft = computed(
      () => state.order?.orderProduct?.productType === ProductType.Draft
    );

    const isTypesettingTag = computed(
      () =>
        state.order?.orderProduct?.productType === ProductType.TypesettingTag
    );

    const isOuterCartonLabel = computed(
      () =>
        state.order?.orderProduct?.productType === ProductType.OuterCartonLabel
    );

    const isInnerCartonLabel = computed(
      () =>
        state.order?.orderProduct?.productType === ProductType.InnerCartonLabel
    );

    const isPriceLabel = computed(
      () => state.order?.orderProduct?.productType === ProductType.PriceLabel
    );

    const clientUserGroupId = computed(() => state.order?.clientUserGroup?.id);

    const product = computed(() => state.order?.product);

    const useUpdateOrder = () => {
      const { mutate: updateOneOrder, error } = useUpdateOneOrderMutation({});
      const handleUpdate = async () => {
        try {
          const orderSafe = state.order;
          assert(orderSafe != null);
          await updateOneOrder({
            input: {
              id: orderSafe.id,
              name: orderSafe.orderProduct.name as string,
              note: orderSafe.orderProduct.note as string,
              draft: true,
              productPriceId: state.productPriceId,
              quantity: orderSafe.orderProduct.quantity,
            },
          });

          await refetch();
        } catch (e) {
          if (error.value == null) throw e;
          state.errorMessages = parseError(error.value);
        }
      };
      return {
        handleUpdate,
      };
    };

    const useRemoveOrder = () => {
      const { mutate: removeOne } = useRemoveOneOrderMutation({});

      const state = reactive({
        visibleRemoveModal: false,
      });

      const handleRemoveConfirm = async () => {
        state.visibleRemoveModal = true;
      };

      const handleRemove = async () => {
        await removeOne({
          input: {
            id: props.orderId,
          },
        });
        router.push({
          name: "DraftList",
        });
      };

      return {
        ...toRefs(state),
        handleRemoveConfirm,
        handleRemove,
      };
    };

    const useCreateChildOrder = () => {
      const state = reactive({
        visibleCreateChildModal: false,
        modalTitle: null as null | string,
        visibleProductList: false,
        visibleProductDetail: false,
        currentProductId: null as null | string,
      });

      const handleVisibleProductList = () => {
        state.modalTitle = "紐づく注文の商品を選択";
        state.visibleCreateChildModal = true;
        state.visibleProductList = true;
        state.visibleProductDetail = false;
      };

      const handleSelectProduct = (productId: string) => {
        state.modalTitle = "紐づく注文の数量と価格を選択";
        state.currentProductId = productId;
        state.visibleProductList = false;
        state.visibleProductDetail = true;
      };

      const handleCloseModal = () => {
        state.visibleProductList = false;
        state.visibleProductDetail = false;
        state.visibleCreateChildModal = false;
      };

      return {
        ...toRefs(state),
        handleVisibleProductList,
        handleSelectProduct,
        handleCloseModal,
      };
    };

    const handleChange = async () => {
      await refetch();
    };

    return {
      ...toRefs(state),
      loading,
      isNormal,
      isDraft,
      isTypesettingTag,
      isOuterCartonLabel,
      isInnerCartonLabel,
      isPriceLabel,
      product,
      ...userSelectClientContacts(clientUserGroupId),
      ...userSelectClientDelivery(clientUserGroupId),
      ...useUpdateOrder(),
      ...useRemoveOrder(),
      ...useCreateChildOrder(),
      handleChange,
      moment,
    };
  },
});
