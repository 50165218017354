<style lang="less" scoped>
@import "../../../styles/variable.less";
.flow {
  &-form {
    &-index {
      font-size: 14px;
      font-weight: bold;
    }
    &-label {
      font-size: 12px;
      color: @clear-black06;
      margin-bottom: 0.6em;
    }
    &-alert {
      background: #fff2f5;
      border-color: #ffccc7;
      max-width: 525px;
      width: 100%;
      position: fixed;
      bottom: 10px;
      right: 10px;
      /deep/ .ant-alert {
        &-message {
          font-size: 14px;
          font-weight: bold;
        }
        &-description {
          font-size: 12px;
        }
      }
    }
  }
  &-name {
    max-width: 420px;
  }
  &-lowerprice {
    &-input {
      text-align: right;
      width: auto;
      max-width: 200px;
      margin-right: 10px;
      /deep/ input {
        text-align: inherit;
      }
      &:hover {
        /deep/ .ant-input-number-input {
          padding-right: 27px;
        }
      }
    }
  }
  &-route {
    .add-flow-btn {
      padding: 0;
      margin-top: 10px;
    }
  }
  &-approve {
    & > * {
      vertical-align: baseline;
    }
    &-index {
      font-weight: normal;
      margin: 0;
      white-space: nowrap;
      padding-right: 20px;
    }
    &-user {
      white-space: nowrap;
      display: inline-block;
      margin-bottom: 10px;
      /deep/ .ant-tag {
        background: none;
        font-size: 14px;
        line-height: 1.575;
        padding: 4px 10px;
        .anticon-close {
          vertical-align: baseline;
        }
      }
    }
    &-join {
      color: @clear-black03;
      margin-right: 8px;
    }
    &-add {
      display: inline-block;
      .ant-btn {
        color: @blue01;
        padding: 4px 10px;
        margin-bottom: 10px;
      }
    }
  }
  &-buttons {
    & > * {
      margin-left: 10px;
    }
  }
}
</style>
<template>
  <div v-if="flow != null">
    <div class="detail-head">
      <a-button class="detail-head-back">
        <router-link
          :to="{
            name: 'SettingsClientUserGroupDetail',
            params: {
              id: flow.clientUserGroup.id,
              tab: 'route',
            },
          }"
        >
          <LeftOutlined />
        </router-link>
      </a-button>
      <h2 class="detail-head-name">承認経路を編集</h2>
    </div>

    <a-form class="flow-form" layout="vertical">
      <a-form-item>
        <p class="flow-form-label">経路名</p>
        <a-input
          class="flow-name"
          v-model:value="flow.name"
          placeholder="経路名"
          @blur="handleUpdate"
        />
      </a-form-item>
    </a-form>
    <a-divider></a-divider>
    <div class="flow-route">
      <div
        class="flow-route-item"
        v-for="(route, index) in flow.routes"
        :key="route.id"
      >
        <h3 class="flow-form-index">
          承認経路{{ index + 1 }}
          <a-button type="link" danger @click="handleRemoveRoute(route)"
            >削除</a-button
          >
        </h3>
        <p class="flow-form-label">経路条件下限金額設定</p>
        <a-form layout="vertical">
          <a-form-item class="flow-lowerprice">
            <a-input-number
              class="flow-lowerprice-input"
              v-model:value="route.condLowerPrice"
              placeholder="下限金額"
              @blur="handleUpdateRoute(route)"
            />円
          </a-form-item>
        </a-form>
        <p class="flow-form-label">承認フロー設定</p>
        <table
          class="flow-approve"
          v-for="(level, index) in route.levels"
          :key="level.id"
        >
          <tr class="flow-approve-row" :wrap="false">
            <th class="flow-approve-index">{{ index + 1 }}次フロー承認者</th>
            <td class="flow-approve-data">
              <div
                class="flow-approve-user"
                v-for="(user, userIndex) in level.userApprovers"
                :key="user.id"
              >
                <span class="flow-approve-join" v-if="userIndex >= 1">or</span>
                <a-tag
                  :closable="true"
                  @close="handleRemoveFlowLevelApprover(level, user)"
                >
                  {{ user.name }}
                </a-tag>
              </div>
              <div class="flow-approve-add">
                <a-button
                  class="add-approve-btn"
                  @click="handleNewFlowLevelApprover(level)"
                >
                  <PlusCircleOutlined />承認者追加
                </a-button>
              </div>
            </td>
          </tr>
        </table>
        <a-button
          class="add-flow-btn"
          type="link"
          @click="handleCreateLevel(route)"
        >
          <PlusCircleOutlined />フロー追加
        </a-button>
        <a-divider></a-divider>
      </div>
      <a-button
        class="add-route-btn"
        type="primary"
        size="large"
        ghost
        @click="handleCreateRoute()"
      >
        承認経路の分岐を追加<ForkOutlined />
      </a-button>
    </div>
    <a-divider></a-divider>
    <a-row class="flow-buttons" justify="end">
      <a-col>
        <a-button class="cancel-route-btn" size="large">
          <router-link
            :to="{
              name: 'SettingsClientUserGroupDetail',
              params: {
                id: flow.clientUserGroup.id,
                tab: 'route',
              },
            }"
            >一覧に戻る</router-link
          >
        </a-button>
      </a-col>
    </a-row>

    <a-alert class="flow-form-alert" v-if="alertVisible" type="error" show-icon>
      <template #message> 入力項目に不備があります。 </template>
      <template #description>
        {{
          invalidColumnNames.join(", ")
        }}が正しく入力されていません。<br />正しく入力されるまで保存は実行できません。
      </template>
    </a-alert>

    <a-modal
      v-model:visible="visibleClientUserModal"
      @ok="handleCreateFlowLevelApprover"
    >
      <template v-if="flow"> </template>
      <a-list item-layout="horizontal" :dataSource="users">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-button
              type="link"
              @click="handleCreateFlowLevelApprover(item)"
              >{{ item.name }}</a-button
            >
          </a-list-item>
        </template>
      </a-list>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, ref } from "vue";
import {
  useGetOneClientApplicationFlowQuery,
  useUpdateOneClientApplicationFlowMutation,
  useCreateOneClientApplicationFlowRouteMutation,
  useUpdateOneClientApplicationFlowRouteMutation,
  useRemoveOneClientApplicationFlowRouteMutation,
  useCreateOneClientApplicationFlowLevelMutation,
  useUpdateOneClientApplicationFlowLevelMutation,
  useRemoveOneClientApplicationFlowLevelMutation,
} from "@/client/modules/api";
import {
  ClientApplicationFlow,
  GetOneClientApplicationFlowQuery,
  ClientApplicationFlowLevel,
  ClientApplicationFlowRoute,
  ClientUser,
} from "@/client/schema";

import { assert, clone } from "@/generic";

import {
  LeftOutlined,
  PlusCircleOutlined,
  ForkOutlined,
} from "@ant-design/icons-vue";

type Props = {
  flowId: string;
  clientUserGroupId: string;
};

export default defineComponent({
  components: {
    LeftOutlined,
    PlusCircleOutlined,
    ForkOutlined,
  },
  props: {
    flowId: {
      type: String,
      required: true,
    },
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const { onResult, refetch } = useGetOneClientApplicationFlowQuery(
      {
        id: props.flowId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const state = reactive({
      flow: null as ClientApplicationFlow | null,
      alertVisible: false,
      invalidColumnNames: [] as string[],
    });

    onResult(({ data }: { data: GetOneClientApplicationFlowQuery }) => {
      state.flow = clone(data.clientApplicationFlow as ClientApplicationFlow);
      state.flow?.routes.sort(
        (
          routeA: ClientApplicationFlowRoute,
          routeB: ClientApplicationFlowRoute
        ) => {
          if (routeA.id < routeB.id) return -1;
          if (routeA.id > routeB.id) return 1;
          return 0;
        }
      );
    });

    const columnCheck = () => {
      const flow = state.flow;
      state.invalidColumnNames = [];
      state.alertVisible = false;
      if (flow) {
        if (!flow.name) {
          state.invalidColumnNames.push("経路名");
          return (state.alertVisible = true);
        }
        if (flow.routes.length) {
          flow.routes.forEach((route, i) => {
            if (route.condLowerPrice === null) {
              state.invalidColumnNames.push(
                `承認経路${i + 1}の経路条件下限金額`
              );
              return (state.alertVisible = true);
            }
          });
        }
        // 承認経路0件エラーなどは、一旦表示しない
        // if (!flow.routes.length) {
        //   state.invalidColumnNames.push("経路分岐")
        //   return state.alertVisible = true;
        // } else {
        //   flow.routes.forEach((route, i) => {
        //     if (route.condLowerPrice === null) {
        //       state.invalidColumnNames.push(`承認経路${i+1}の経路条件下限金額`)
        //       return state.alertVisible = true;
        //     }
        //     if (!route.levels.length) {
        //       state.invalidColumnNames.push(`承認経路${i+1}の承認フロー`)
        //       return state.alertVisible = true;
        //     } else {
        //       route.levels.forEach((level, j) => {
        //         if (!level.userApprovers.length) {
        //           state.invalidColumnNames.push(`承認経路${i+1}の承認フロー${j+1}の承認者`)
        //           return state.alertVisible = true;
        //         }
        //       })
        //     }
        //   })
        // }
      }
    };

    const useUpdateFlow = () => {
      const { mutate } = useUpdateOneClientApplicationFlowMutation({});

      const handleUpdate = () => {
        columnCheck();
        if (!state.alertVisible) {
          assert(state.flow != null);
          mutate({
            input: {
              id: state.flow.id,
              name: state.flow.name,
            },
          });
        }
      };

      return {
        handleUpdate,
      };
    };

    const useCreateFlowRoute = () => {
      const { mutate } = useCreateOneClientApplicationFlowRouteMutation({});

      const handleCreateRoute = async () => {
        assert(state.flow != null);
        await mutate({
          input: {
            flowId: state.flow.id,
            condLowerPrice: 0,
          },
        });
        await refetch();
      };

      return {
        handleCreateRoute,
      };
    };

    const useUpdateFlowRoute = () => {
      const { mutate } = useUpdateOneClientApplicationFlowRouteMutation({});

      const handleUpdateRoute = async (
        clientApplicationFlowRoute: ClientApplicationFlowRoute
      ) => {
        columnCheck();
        if (!state.alertVisible) {
          await mutate({
            input: {
              id: clientApplicationFlowRoute.id,
              condLowerPrice: clientApplicationFlowRoute.condLowerPrice,
            },
          });
          await refetch();
        }
      };

      return {
        handleUpdateRoute,
      };
    };

    const useRemoveFlowRoute = () => {
      const { mutate } = useRemoveOneClientApplicationFlowRouteMutation({});

      const handleRemoveRoute = async (
        clientApplicationFlowRoute: ClientApplicationFlowRoute
      ) => {
        await mutate({
          input: {
            id: clientApplicationFlowRoute.id,
          },
        });
        await refetch();
      };

      return {
        handleRemoveRoute,
      };
    };

    const useCreateFlowLevel = () => {
      const { mutate } = useCreateOneClientApplicationFlowLevelMutation({});

      const handleCreateLevel = async (
        clientApplicationFlowRoute: ClientApplicationFlowRoute
      ) => {
        const level =
          clientApplicationFlowRoute.levels.length === 0
            ? 1
            : Math.max(
                ...clientApplicationFlowRoute.levels.map((level) => level.level)
              ) + 1;
        await mutate({
          input: {
            routeId: clientApplicationFlowRoute.id,
            level: level,
          },
        });
        await refetch();
      };

      return {
        handleCreateLevel,
      };
    };

    const useCreateFlowLevelApprover = () => {
      const { mutate } = useUpdateOneClientApplicationFlowLevelMutation({});

      const approverState = reactive({
        visibleClientUserModal: false,
        approver: null as ClientApplicationFlowLevel | null,
      });

      const currentClientApplicationFlowLevel = ref(
        null as ClientApplicationFlowLevel | null
      );

      const users = computed(() => {
        const clientApplicationFlowLevel =
          currentClientApplicationFlowLevel.value;
        if (clientApplicationFlowLevel == null) return [];
        const ids = clientApplicationFlowLevel.userApprovers.map(
          (approver) => approver.id
        );
        return state.flow?.clientUserGroup?.members.filter(
          (user) => !ids.includes(user.id)
        );
      });

      const handleNewFlowLevelApprover = (
        clientApplicationFlowLevel: ClientApplicationFlowLevel
      ) => {
        approverState.visibleClientUserModal = true;
        currentClientApplicationFlowLevel.value = clientApplicationFlowLevel;
      };

      const handleCreateFlowLevelApprover = async (clientUser: ClientUser) => {
        assert(currentClientApplicationFlowLevel.value != null);

        // 既に存在する場合
        if (
          currentClientApplicationFlowLevel.value.userApprovers.some(
            (approver: ClientUser) => approver.id === clientUser.id
          )
        )
          return;

        const newUserApproverIds = [
          clientUser.id,
          ...currentClientApplicationFlowLevel.value.userApprovers.map(
            (approver) => approver.id
          ),
        ];

        await mutate({
          input: {
            id: currentClientApplicationFlowLevel.value.id,
            userApproverIds: newUserApproverIds,
          },
        });
        await refetch();
        approverState.visibleClientUserModal = false;
      };

      const handleRemoveFlowLevelApprover = async (
        clientApplicationFlowLevel: ClientApplicationFlowLevel,
        clientUser: ClientUser
      ) => {
        const newUserApproverIds = [
          ...clientApplicationFlowLevel.userApprovers
            .filter((approver) => approver.id !== clientUser.id)
            .map((approver) => approver.id),
        ];

        await mutate({
          input: {
            id: clientApplicationFlowLevel.id,
            userApproverIds: newUserApproverIds,
          },
        });
        await refetch();
      };

      return {
        ...toRefs(approverState),
        users,
        handleNewFlowLevelApprover,
        handleCreateFlowLevelApprover,
        handleRemoveFlowLevelApprover,
      };
    };

    const useRemoveFlowLevel = () => {
      const { mutate } = useRemoveOneClientApplicationFlowLevelMutation({});

      const handleRemoveLevel = async (
        clientApplicationFlowLevel: ClientApplicationFlowLevel
      ) => {
        await mutate({
          input: {
            id: clientApplicationFlowLevel.id,
          },
        });
        await refetch();
      };

      return {
        handleRemoveLevel,
      };
    };

    // watch(
    //   () => state.flow,
    //   (val) => {
    //     state.invalidColumnNames = [];
    //     if (val) {
    //       console.log(val.name);
    //       if (!val.name) {
    //          state.invalidColumnNames.push("経路名")
    //         return state.alertVisible = true;
    //       }
    //       if (!val.routes.length) {
    //         state.invalidColumnNames.push("経路分岐")
    //         return state.alertVisible = true;
    //       } else {
    //         val.routes.forEach((route, i) => {
    //           if (route.condLowerPrice === null) {
    //             state.invalidColumnNames.push(`承認経路${i+1}の経路条件下限金額`)
    //             return state.alertVisible = true;
    //           }
    //           if (!route.levels.length) {
    //             state.invalidColumnNames.push(`承認経路${i+1}の承認フロー`)
    //             return state.alertVisible = true;
    //           } else {
    //             route.levels.forEach((level, j) => {
    //               if (!level.userApprovers.length) {
    //                 state.invalidColumnNames.push(`承認経路${i+1}の承認フロー${j+1}の承認者`)
    //                 return state.alertVisible = true;
    //               } else {
    //                 return state.alertVisible = false;
    //               }
    //             })
    //           }
    //         })
    //       }
    //     }
    //   }
    // );

    const handleClose = () => {
      state.alertVisible = false;
    };

    return {
      ...toRefs(state),
      ...useUpdateFlow(),
      ...useCreateFlowRoute(),
      ...useUpdateFlowRoute(),
      ...useRemoveFlowRoute(),
      ...useCreateFlowLevel(),
      ...useCreateFlowLevelApprover(),
      ...useRemoveFlowLevel(),
      handleClose,
    };
  },
});
</script>
