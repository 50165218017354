<style lang="less" scoped>
@import "../../styles/variable.less";
/deep/ .ant-table {
  &-fixed {
    border-top: solid 1px @clear-black02;
  }
  &-thead {
    white-space: nowrap;
    & > tr {
      & > th {
        font-size: 10px;
        color: @clear-black03;
        background: none;
        border: none;
      }
    }
  }
  &-tbody {
    & > tr {
      & > td {
        border-bottom: none;
      }
      &:nth-child(odd) {
        background: @white03;
      }
    }
    .number {
      display: inline-block;
      min-width: initial;
      min-width: auto;
    }
    .order-note {
      max-width: 300px;
    }
  }
}
</style>
<template>
  <a-table
    :data-source="orders"
    :columns="columns"
    :row-key="(record) => record.id"
    :loading="loading"
    :scroll="{ x: 'max-content' }"
    :pagination="false"
  >
    <template #expandIcon="{ record, expanded, onExpand }">
      <template v-if="record.children == null || record.children.length == 0">
        <span
          v-if="!record.parent"
          class="ant-table-row-indent indent-level-1"
          style="padding-left: 20px"
        ></span>
        <span class="ant-table-row-expand-icon ant-table-row-spaced" />
      </template>
      <template v-else-if="expanded">
        <a-button
          type="link"
          @click="onExpand(record, $event)"
          class="ant-table-row-expand-icon`ant-table-row-expanded"
        >
          <RightSquareOutlined />
        </a-button>
      </template>
      <template v-else>
        <a-button
          type="link"
          @click="onExpand(record, $event)"
          class="ant-table-row-expand-icon`ant-table-row-collapsed"
        >
          <DownSquareOutlined />
        </a-button>
      </template>
    </template>
    <template #orderNo="{ record }">
      <template v-if="record.orderNo == null">
        <router-link
          v-if="record.orderedAt == null"
          :to="{
            name: 'OrderEdit',
            params: {
              id: record.id,
            },
          }"
        >
          注文を再開する
        </router-link>
        <router-link
          v-else
          :to="{
            name: 'OrderDetail',
            params: {
              id: record.id,
            },
          }"
        >
          入稿データをアップロード
        </router-link>
      </template>
      <template v-else>
        <router-link
          :to="{
            name: 'OrderDetail',
            params: {
              id: record.id,
            },
          }"
        >
          {{ record.orderNo }}{{ pagination }}
        </router-link>
      </template>
    </template>
    <template #productName="{ record }">
      {{ record.product }}
    </template>
    <template #applicationStatus="{ text }">{{
      $t("enums.application_status_type." + text)
    }}</template>
    <template #uploadStatus="{ text }">{{
      $t("enums.upload_status_type." + text)
    }}</template>
    <template #deliveryStatus="{ text }">{{
      $t("enums.delivery_status_type." + text)
    }}</template>
    <template #note="{ text }">
      <div class="order-note">{{ text }}</div>
    </template>
    <template #number="{ text }">
      <Number :value="text"></Number>
    </template>
    <template #currency="{ text }">
      <Currency :value="text"></Currency>
    </template>
    <template #date="{ text }">{{ formatDate(text) }}</template>
    <template #dateTimestamp="{ text }">{{ formatTimestamp(text) }}</template>
  </a-table>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatDate, formatTimestamp, Currency, Number } from "@/generic";
import { useI18n } from "@/shared/providors/i18n";
import { underscore } from "inflected";
import { RightSquareOutlined, DownSquareOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    Currency,
    Number,
    RightSquareOutlined,
    DownSquareOutlined,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { ta, t } = useI18n();
    const columns = [
      {
        title: ta("clientUserGroup"),
        dataIndex: "clientUserGroup.name",
        key: "clientUserGroup.name",
      },
      {
        title: ta("orderNo"),
        dataIndex: "orderNo",
        key: "orderNo",
        slots: {
          customRender: "orderNo",
        },
      },
      {
        title: ta("orderProductName"),
        dataIndex: "orderProduct.name",
        key: "orderProduct.name",
      },
      {
        title: t(`attributes.${underscore("order.clientUser")}`),
        dataIndex: "clientUser.name",
        key: "clientUser.name",
      },
      {
        title: ta("orderStatus"),
        dataIndex: "orderStatus.name",
        key: "orderStatus.name",
      },
      {
        title: ta("applicationStatus"),
        dataIndex: "applicationStatus",
        key: "applicationStatus",
        slots: {
          customRender: "applicationStatus",
        },
      },
      {
        title: ta("uploadStatus"),
        dataIndex: "uploadStatus",
        key: "uploadStatus",
        slots: {
          customRender: "uploadStatus",
        },
      },
      {
        title: ta("deliveryStatus"),
        dataIndex: "deliveryStatus",
        key: "deliveryStatus",
        slots: {
          customRender: "deliveryStatus",
        },
      },
      {
        title: ta("product"),
        dataIndex: "product.name",
        key: "product.name",
      },
      {
        title: ta("quantity"),
        dataIndex: "orderProduct.quantity",
        key: "orderProduct.quantity",
        slots: {
          customRender: "number",
        },
      },
      {
        title: ta("price"),
        dataIndex: "orderProduct.price",
        key: "orderProduct.price",
        slots: {
          customRender: "currency",
        },
      },
      {
        title: ta("basicDueDate"),
        dataIndex: "orderProduct.basicDueDate",
        key: "orderProduct.basicDueDate",
        slots: {
          customRender: "number",
        },
      },
      {
        title: ta("shippingFee"),
        dataIndex: "shippingFee",
        key: "shippingFee",
        slots: {
          customRender: "currency",
        },
      },
      {
        title: ta("amount"),
        dataIndex: "amount",
        key: "amount",
        slots: {
          customRender: "currency",
        },
      },
      {
        title: ta("orderedAt"),
        dataIndex: "orderedAt",
        key: "orderedAt",
        slots: {
          customRender: "dateTimestamp",
        },
        readonly: true,
      },
      {
        title: ta("deliveryAt"),
        dataIndex: "deliveryAt",
        key: "deliveryAt",
        slots: {
          customRender: "date",
        },
        readonly: true,
      },
      {
        title: ta("note"),
        dataIndex: "orderProduct.note",
        key: "orderProduct.note",
        slots: {
          customRender: "note",
        },
      },
    ];

    return {
      columns,
      formatDate,
      formatTimestamp,
    };
  },
});
</script>
