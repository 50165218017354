
import { defineComponent } from "vue";
import {
  useGetOneOrderQuery,
  useCancelOrderMutation,
  useCopyOneOrderMutation,
  useUpdateOneOrderMutation,
} from "@/client/modules/api";
import { useResult } from "@vue/apollo-composable";
import { formatTimestamp, Currency, Number } from "@/generic";
import { useRouter } from "vue-router";
import { LeftOutlined } from "@ant-design/icons-vue";
import UploadButton from "./UploadButton.vue";
import TypesettingButton from "./TypesettingButton.vue";
import OuterCartonTypesettingButton from "./OuterCartonTypesettingButton.vue";
import InnerCartonParamPreviewButton from "./InnerCartonParamPreviewButton.vue";
import PriceLabelParamPreviewButton from "./PriceLabelParamPreviewButton.vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import UploadFileList from "@/shared/components/UploadFileList.vue";

export type Props = {
  orderId: string;
};

export default defineComponent({
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  components: {
    UploadButton,
    TypesettingButton,
    OuterCartonTypesettingButton,
    InnerCartonParamPreviewButton,
    PriceLabelParamPreviewButton,
    Currency,
    Number,
    LeftOutlined,
    UploadFileList,
    ImagePreview,
  },
  setup(props: Props) {
    const router = useRouter();

    const { result, loading, refetch } = useGetOneOrderQuery(
      {
        id: props.orderId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const order = useResult(result);

    const useCancelOrder = () => {
      const { mutate: cancel } = useCancelOrderMutation({});
      const handleCancel = async () => {
        await cancel({
          input: {
            id: props.orderId,
          },
        });
      };
      return {
        handleCancel,
      };
    };

    const useReorderOrder = () => {
      const handleReorder = async () => {
        router.push({
          name: "OrderEdit",
          params: {
            id: props.orderId,
          },
        });
      };
      return {
        handleReorder,
      };
    };

    const useCopyOrder = () => {
      const { mutate: copyOne } = useCopyOneOrderMutation({});

      const handleCopy = async () => {
        const { data } = await copyOne({
          input: {
            id: props.orderId,
          },
        });
        const newOrderId = data?.copyOneOrder?.order?.id as string;
        router.push({
          name: "OrderEdit",
          params: {
            id: newOrderId,
          },
        });
      };

      return {
        handleCopy,
      };
    };

    const handleChange = async () => {
      await refetch();
    };

    const useUpdateOrder = () => {
      const { mutate: updateOneOrder } = useUpdateOneOrderMutation({});

      const handleUploaded = async () => {
        await updateOneOrder({
          input: {
            id: props.orderId,
            draft: false,
          },
        });
        await refetch();
      };

      return {
        handleUploaded,
      };
    };

    return {
      order,
      loading,
      ...useCancelOrder(),
      ...useReorderOrder(),
      ...useCopyOrder(),
      ...useUpdateOrder(),
      formatTimestamp,
      handleChange,
    };
  },
});
