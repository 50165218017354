<style lang="less">
@import "../../styles/variable.less";
.order-param-input-modal {
  .content {
    .main-content {
      padding: 10px 10px 0 10px;
    }
  }
}
</style>
<template>
  <div v-if="priceLabelParam">
    <a-button
      size="large"
      type="primary"
      v-if="!previewOnly"
      @click="handleVisibleParamInput"
    >
      ラベル情報設定
    </a-button>

    <a-modal
      v-if="!loading"
      class="order-param-input-modal"
      width="600px"
      title="ラベル情報設定"
      v-model:visible="visibleParamInput"
      :okButtonProps="{ style: 'display: none' }"
    >
      <template #footer>
        <a-button size="large" key="back" @click="handleClose">閉じる</a-button>
      </template>
      <div class="content">
        <a-form
          :model="priceLabelParam"
          layout="horizontal"
          :labelCol="{ span: 8 }"
        >
          <div class="main-content">
            <a-form-item :label="$t('attributes.jan_code')">
              <a-input
                v-model:value="priceLabelParam.janCode"
                @blur="handleUpdate"
              />
            </a-form-item>
            <a-form-item :label="$t('attributes.tax_included_price')">
              <InputCurrency
                v-model:value="priceLabelParam.taxIncludedPrice"
                @blur="handleUpdate"
              />
            </a-form-item>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { clone, parseError } from "@/generic";
import {
  useUpdateOnePriceLabelParamMutation,
  useGetOnePriceLabelParamQuery,
} from "@/client/modules/api";
import { InputCurrency } from "@/generic/components/entry";
import { message } from "ant-design-vue";

export type Props = {
  priceLabelParamId: string;
};

export default defineComponent({
  components: {
    InputCurrency,
  },
  props: {
    priceLabelParamId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const state = reactive({
      priceLabelParam: null as null | any,
      visibleParamInput: false,
      errorMessages: [] as string[],
    });

    const { loading, onResult, refetch } = useGetOnePriceLabelParamQuery(
      {
        id: props.priceLabelParamId,
      },
      {
        fetchPolicy: "network-only",
      }
    );
    onResult((result) => {
      state.priceLabelParam = clone(result.data.priceLabelParam);
    });

    const handleVisibleParamInput = () => {
      state.visibleParamInput = true;
    };

    const { mutate: update, error: updateError } =
      useUpdateOnePriceLabelParamMutation({});

    const handleUpdate = async () => {
      const safePriceLabelParam = state.priceLabelParam;
      try {
        if (safePriceLabelParam == null) return;
        state.errorMessages = [];
        await update({
          input: {
            id: props.priceLabelParamId,
            janCode: safePriceLabelParam.janCode,
            taxIncludedPrice: safePriceLabelParam.taxIncludedPrice,
          },
        });

        await refetch();
      } catch (e) {
        if (updateError.value == null) throw e;
        const errorMessages = parseError(updateError.value);
        errorMessages.forEach((msg: string) => message.error(msg));
      }
    };

    const handleClose = () => {
      state.visibleParamInput = false;
    };

    return {
      ...toRefs(state),
      loading,
      handleVisibleParamInput,
      handleUpdate,
      handleClose,
    };
  },
});
</script>
