<style lang="less" scoped>
.content-layout {
  .ant-tabs {
    overflow: visible;
  }
}
</style>
<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">スタッフグループ</h1>
      <ClientUserGroupNav :clientUserGroupId="id"></ClientUserGroupNav>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <Content
        :style="{
          margin: 0,
          padding: '20px',
          height: 'auto',
        }"
        :key="id"
      >
        <ClientUserGroupDetail :clientUserGroupId="id"></ClientUserGroupDetail>
        <a-tabs :defaultActiveKey="tab" @change="handleChangeTab">
          <a-tab-pane key="memberships" tab="メンバー">
            <ClientUserGroupMembershipList
              :clientUserGroupId="id"
            ></ClientUserGroupMembershipList>
          </a-tab-pane>
          <a-tab-pane key="products" tab="商品">
            <ClientUserGroupProductList
              :clientUserGroupId="id"
            ></ClientUserGroupProductList>
          </a-tab-pane>
          <a-tab-pane key="delivery" tab="スタッフ配送先">
            <ClientDeliveryDetail
              :clientUserGroupId="id"
            ></ClientDeliveryDetail>
          </a-tab-pane>
          <a-tab-pane key="contact" tab="スタッフ連絡先">
            <ClientContactDetail :clientUserGroupId="id"></ClientContactDetail>
          </a-tab-pane>
          <a-tab-pane key="route" tab="承認経路">
            <ApplicationFlowList :clientUserGroupId="id"></ApplicationFlowList>
          </a-tab-pane>
        </a-tabs>
      </Content>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, unref } from "vue";
import { Content } from "@/generic";
import ClientUserGroupDetail from "@/client/components/settings/clientUserGroup/Detail.vue";
import ClientUserGroupNav from "@/client/components/settings/clientUserGroup/Nav.vue";
import ClientUserGroupMembershipList from "@/client/components/settings/clientUserGroupMembership/List.vue";
import ClientUserGroupProductList from "@/client/components/settings/clientUserGroupProduct/List.vue";
import ClientDeliveryDetail from "@/client/components/settings/clientDelivery/Detail.vue";
import ClientContactDetail from "@/client/components/settings/clientContact/Detail.vue";

import ApplicationFlowList from "@/client/components/settings/applicationFlow/List.vue";
import { useRoute, useRouter } from "vue-router";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    ClientUserGroupNav,
    ClientUserGroupDetail,
    ClientUserGroupMembershipList,
    ClientUserGroupProductList,
    ClientDeliveryDetail,
    ClientContactDetail,
    ApplicationFlowList,
    UserName,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: false,
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const handleChangeTab = (activeKey: string) => {
      const name = unref(route.name) || undefined;
      const params = {
        ...unref(route.params),
        tab: activeKey,
      };
      router.replace({
        name: name,
        params: params,
      });
    };

    return {
      handleChangeTab,
    };
  },
});
</script>
