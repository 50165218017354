import { computed, unref, ref, Ref } from "vue";
import {
  useGetManyClientContactQuery,
  useGetManyClientDeliveryQuery,
} from "@/client/modules/api";
import {
  GetManyClientContactQuery,
  GetManyClientDeliveryQuery,
  ClientContact,
  OrderContact,
  ClientDelivery,
  OrderDelivery,
} from "@/client/schema";
import { useResult } from "@vue/apollo-composable";
import { useI18n } from "@/shared/providors/i18n";

// 連絡先選択時の処理
export const userSelectClientContacts = (
  clientUserGroupId: string | undefined | Ref<string | undefined>
) => {
  const { te } = useI18n();
  const { result } = useGetManyClientContactQuery(() => ({
    clientUserGroupId: unref(clientUserGroupId),
  }));

  const clientContacts =
    useResult<GetManyClientContactQuery, "clientContacts">(result);

  const clientContactVisible = ref(false);

  const clientContactOptions = computed(
    () =>
      unref(clientContacts)?.map((clientContact) => ({
        value: clientContact,
        label: [
          te(clientContact.prefecture, "prefecture"),
          clientContact.city,
          clientContact.block,
          clientContact.building,
          clientContact.name,
        ].join(""),
      })) || []
  );

  const handleSelectContact = (
    orderContact: OrderContact,
    clientContact: ClientContact
  ) => {
    Object.assign(orderContact, {
      zipCode: clientContact.zipCode,
      prefecture: clientContact.prefecture,
      city: clientContact.city,
      block: clientContact.block,
      building: clientContact.building,
      name: clientContact.name,
      tel: clientContact.tel,
      fax: clientContact.fax,
    });
    clientContactVisible.value = false;
  };

  return {
    clientContactOptions,
    clientContactVisible,
    handleSelectContact,
  };
};

// 配送先選択時の処理
export const userSelectClientDelivery = (
  clientUserGroupId: string | undefined | Ref<string | undefined>
) => {
  const { te } = useI18n();

  const { result } = useGetManyClientDeliveryQuery(() => ({
    clientUserGroupId: unref(clientUserGroupId),
  }));

  const clientDeliveries =
    useResult<GetManyClientDeliveryQuery, "clientDeliveries">(result);
  const clientDeliveryVisible = ref(false);

  const clientDeliveryOptions = computed(
    () =>
      unref(clientDeliveries)?.map((clientDelivery) => ({
        value: clientDelivery,
        label: [
          te(clientDelivery.prefecture, "prefecture"),
          clientDelivery.city,
          clientDelivery.block,
          clientDelivery.building,
          clientDelivery.name,
        ].join(""),
      })) || []
  );

  const handleSelectDelivery = (
    orderDelivery: OrderDelivery,
    clientDelivery: ClientDelivery
  ) => {
    Object.assign(orderDelivery, {
      zipCode: clientDelivery.zipCode,
      prefecture: clientDelivery.prefecture,
      city: clientDelivery.city,
      block: clientDelivery.block,
      building: clientDelivery.building,
      name: clientDelivery.name,
      shipperName: clientDelivery.shipperName,
      tel: clientDelivery.tel,
      fax: clientDelivery.fax,
    });
    clientDeliveryVisible.value = false;
  };

  return {
    clientDeliveryVisible,
    clientDeliveryOptions,
    handleSelectDelivery,
  };
};
