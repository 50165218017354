
import { computed, defineComponent, reactive, ref, toRefs, unref } from "vue";
import { message } from "ant-design-vue";
import {
  ReloadOutlined,
  PaperClipOutlined,
  RightSquareOutlined,
  DownSquareOutlined,
} from "@ant-design/icons-vue";
import { useResult } from "@vue/apollo-composable";
import {
  useGetManyOrderApprovalRecordQuery,
  useApproveManyOrderApprovalRecordMutation,
  useRejectOrderApprovalRecordMutation,
} from "@/client/modules/api";
import { useUploadResource } from "@/client/components/orderComment/modules/uploadResource";
import { assert, formatDate, formatTimestamp, Content } from "@/generic";
import { useI18n } from "@/shared/providors/i18n";
import { OrderApprovalRecord } from "../../schema";
import SubmitIcon from "@/shared/components/svg-icon/Submit.vue";
// import { default as SearchTool, Search } from "./SearchTool.vue";

import moment from "moment";
import "moment/locale/ja";
moment.locale("ja");

interface FileItem {
  uid: string;
  name: string;
  status?: string;
  response?: string;
  url?: string;
  preview?: string;
  originFileObj?: any;
  file: string | Blob;
}

export default defineComponent({
  components: {
    Content,
    PaperClipOutlined,
    SubmitIcon,
    RightSquareOutlined,
    DownSquareOutlined,
    // SearchTool,
  },
  setup() {
    const { ta } = useI18n();
    const formRef = ref();

    const { result, refetch, loading } = useGetManyOrderApprovalRecordQuery({
      current: true,
    });

    const columns = [
      {
        title: ta("orderNo"),
        dataIndex: "orderNo",
        key: "orderNo",
        slots: {
          customRender: "orderNo",
        },
        class: "cell-number",
      },
      {
        title: ta("productName"),
        dataIndex: "productName",
        key: "productName",
        slots: {
          customRender: "productName",
        },
      },
      {
        title: ta("clientUser"),
        dataIndex: "clientUser",
        key: "clientUser",
        slots: {
          customRender: "clientUser",
        },
      },
      {
        title: ta("orderedAt"),
        dataIndex: "orderedAt",
        key: "orderedAt",
        slots: {
          customRender: "orderedAt",
        },
      },
      {
        title: ta("basicDueDate"),
        dataIndex: "basicDueDate",
        key: "basicDueDate",
        slots: {
          customRender: "basicDueDate",
        },
      },
      {
        title: ta("clientUserGroup"),
        dataIndex: "clientUserGroup",
        key: "clientUserGroup",
        slots: {
          customRender: "clientUserGroup",
        },
      },
      {
        title: "",
        key: "action",
        slots: {
          customRender: "action",
        },
        class: "cell-action",
      },
    ];

    const orderApproveRecords = ref(useResult(result));

    const handleRefetch = async () => {
      await refetch();
    };

    const useApprove = () => {
      const { mutate: approve } = useApproveManyOrderApprovalRecordMutation({});

      const state = reactive({
        visbleApproveConfirm: false,
        approveRecord: null as OrderApprovalRecord | null,
      });

      const handleApproveConfirm = (record: OrderApprovalRecord) => {
        state.approveRecord = record;
        state.visbleApproveConfirm = true;
      };

      const handleApprove = async () => {
        assert(state.approveRecord != null);
        await approve({
          input: {
            ids: [state.approveRecord.id],
          },
        });
        await refetch();

        state.approveRecord = null;
        state.visbleApproveConfirm = false;
      };

      return {
        ...toRefs(state),
        handleApproveConfirm,
        handleApprove,
      };
    };

    const useApproveAll = () => {
      const { mutate: approve } = useApproveManyOrderApprovalRecordMutation({});

      const state = reactive({
        approveRecordIds: [] as string[],
      });

      const handleSelectApproveRecord = (selectedRowKeys: string[]) => {
        state.approveRecordIds = selectedRowKeys;
      };

      const checkboxProps = (record: OrderApprovalRecord) => {
        return {
          disabled: record.parent !== null,
        };
      };

      const disableApproveAll = computed(
        () => state.approveRecordIds.length === 0
      );

      const handleApproveAll = async () => {
        await approve({
          input: {
            ids: state.approveRecordIds,
          },
        });
        await refetch();
      };

      return {
        ...toRefs(state),
        disableApproveAll,
        handleApproveAll,
        handleSelectApproveRecord,
        checkboxProps,
      };
    };

    const useReject = () => {
      const {
        mutate: reject,
        onDone,
        onError,
        error,
      } = useRejectOrderApprovalRecordMutation({});

      const state = reactive({
        visbleRejectConfirm: false,
        rejectMessage: null as string | null,
        rejectRecord: null as OrderApprovalRecord | null,
        fileList: [] as FileItem[],
        errorMessages: [] as string[],
      });

      const handleRejectConfirm = (record: OrderApprovalRecord) => {
        state.rejectMessage = null;
        state.rejectRecord = record;
        state.visbleRejectConfirm = true;
      };

      const handleReject = async () => {
        assert(state.rejectRecord != null);
        await reject({
          input: {
            id: state.rejectRecord.id,
            message: state.rejectMessage,
          },
        });
        await refetch();

        state.rejectRecord = null;
        state.visbleRejectConfirm = false;
      };
      const handleCancel = () => {
        state.visbleRejectConfirm = false;
      };

      const { uploadData, errorMessages: importErrorMessages } =
        useUploadResource();

      const beforeUpload = (file: FileItem) => {
        state.fileList = [...state.fileList, file];
        return false;
      };

      const fileUpload = (id: string) => {
        state.fileList.forEach((file) => {
          const fileExt = file.name.split(".").pop();
          uploadData(id, file, fileExt).then(async (result: boolean) => {
            // 非同期に呼び出したいのでawaitにしないこと
            if (result) {
              message.success("否認しました。");
            } else {
              const errorMessages = unref(importErrorMessages);
              Object.keys(errorMessages).forEach((key) => {
                message.error(errorMessages[key]);
              });
            }
          });
        });
      };

      onDone((result) => {
        assert(result.data != null);
        assert(result.data.rejectOrderApprovalRecord != null);
        assert(result.data.rejectOrderApprovalRecord.orderComment != null);
        fileUpload(result.data.rejectOrderApprovalRecord.orderComment.id);
        state.rejectMessage = "";
        state.fileList = [];
      });

      onError(() => {
        state.errorMessages = error.value.message.split(",");
      });

      const handleRemoveFile = (file: FileItem) => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        state.fileList = newFileList;
      };

      return {
        ...toRefs(state),
        handleReject,
        handleRejectConfirm,
        beforeUpload,
        handleRemoveFile,
        handleCancel,
      };
    };

    return {
      columns,
      orderApproveRecords,
      handleRefetch,
      ...useApprove(),
      ...useApproveAll(),
      ...useReject(),
      formatDate,
      formatTimestamp,
      loading,
      ReloadOutlined,
      formRef,
      moment,
    };
  },
});
