
import { defineComponent } from "vue";
import { formatDate, formatTimestamp, Currency, Number } from "@/generic";
import { useI18n } from "@/shared/providors/i18n";
import { underscore } from "inflected";
import { RightSquareOutlined, DownSquareOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    Currency,
    Number,
    RightSquareOutlined,
    DownSquareOutlined,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { ta, t } = useI18n();
    const columns = [
      {
        title: ta("clientUserGroup"),
        dataIndex: "clientUserGroup.name",
        key: "clientUserGroup.name",
      },
      {
        title: ta("orderNo"),
        dataIndex: "orderNo",
        key: "orderNo",
        slots: {
          customRender: "orderNo",
        },
      },
      {
        title: ta("orderProductName"),
        dataIndex: "orderProduct.name",
        key: "orderProduct.name",
      },
      {
        title: t(`attributes.${underscore("order.clientUser")}`),
        dataIndex: "clientUser.name",
        key: "clientUser.name",
      },
      {
        title: ta("orderStatus"),
        dataIndex: "orderStatus.name",
        key: "orderStatus.name",
      },
      {
        title: ta("applicationStatus"),
        dataIndex: "applicationStatus",
        key: "applicationStatus",
        slots: {
          customRender: "applicationStatus",
        },
      },
      {
        title: ta("uploadStatus"),
        dataIndex: "uploadStatus",
        key: "uploadStatus",
        slots: {
          customRender: "uploadStatus",
        },
      },
      {
        title: ta("deliveryStatus"),
        dataIndex: "deliveryStatus",
        key: "deliveryStatus",
        slots: {
          customRender: "deliveryStatus",
        },
      },
      {
        title: ta("product"),
        dataIndex: "product.name",
        key: "product.name",
      },
      {
        title: ta("quantity"),
        dataIndex: "orderProduct.quantity",
        key: "orderProduct.quantity",
        slots: {
          customRender: "number",
        },
      },
      {
        title: ta("price"),
        dataIndex: "orderProduct.price",
        key: "orderProduct.price",
        slots: {
          customRender: "currency",
        },
      },
      {
        title: ta("basicDueDate"),
        dataIndex: "orderProduct.basicDueDate",
        key: "orderProduct.basicDueDate",
        slots: {
          customRender: "number",
        },
      },
      {
        title: ta("shippingFee"),
        dataIndex: "shippingFee",
        key: "shippingFee",
        slots: {
          customRender: "currency",
        },
      },
      {
        title: ta("amount"),
        dataIndex: "amount",
        key: "amount",
        slots: {
          customRender: "currency",
        },
      },
      {
        title: ta("orderedAt"),
        dataIndex: "orderedAt",
        key: "orderedAt",
        slots: {
          customRender: "dateTimestamp",
        },
        readonly: true,
      },
      {
        title: ta("deliveryAt"),
        dataIndex: "deliveryAt",
        key: "deliveryAt",
        slots: {
          customRender: "date",
        },
        readonly: true,
      },
      {
        title: ta("note"),
        dataIndex: "orderProduct.note",
        key: "orderProduct.note",
        slots: {
          customRender: "note",
        },
      },
    ];

    return {
      columns,
      formatDate,
      formatTimestamp,
    };
  },
});
